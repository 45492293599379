import { useTranslation } from "react-i18next";

function StatusBadge(status: any) {
  const { t } = useTranslation();

  let badgeColor: string;

  if (status.status === "accepted") {
    badgeColor = "bg-green-100 text-green-800";
  } else if (status.status === "refused") {
    badgeColor = "bg-red-100 text-red-800";
  } else {
    badgeColor = "bg-grey-100 text-grey-800";
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${badgeColor}`}
    >
      {t(`common.${status.status}`)}
    </span>
  );
}

export default StatusBadge;
