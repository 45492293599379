import ConfirmWrap from "common/components/ConfirmWrap/ConfirmWrap";
import { MailIcon } from "@heroicons/react/solid";

type Props = {
  onClick: () => void;
  icon: any;
  color?: string;
  confirm?: boolean;
  confirmMessage?: string;
  label?: string;
  transparent?: boolean;
  outlined?: boolean;
  loading?: boolean;
  className?: string;
  title?: string;
};

function Button({
  onClick,
  icon = <MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />,
  color,
  confirm = false,
  // transparent = false,
  confirmMessage,
  label,
  // outlined = false,
  loading = false,
  className,
  ...rest
}: Props) {
  /* const classNames = ['button', additionnalClasses];

  if (transparent) classNames.push('is-transparent');
  if (outlined) classNames.push('is-outlined');
  if (color) classNames.push(`is-${color}`); */

  const btn = (
    <button
      type="button"
      onClick={onClick}
      className={`inline-flex items-center 
        px-3 py-2 
        border border-transparent shadow-sm 
        text-sm font-medium text-white  
        rounded-md leading-4
        bg-indigo-600 
        hover:bg-indigo-700 
        focus:outline-none 
        focus:ring-2 
        focus:ring-offset-2
        focus:ring-indigo-500 ${className}`}
      disabled={loading}
      {...rest}
    >
      {icon}
      {label && <span className={icon ? "ml-2" : undefined}>{label}</span>}

      {loading && (
        <span className="icon">
          <i className="fas fa-spinner fa-spin" />
        </span>
      )}
    </button>
  );

  if (confirm) {
    return <ConfirmWrap message={confirmMessage}>{btn}</ConfirmWrap>;
  }
  return btn;
}

export default Button;
