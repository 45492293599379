import PageHeader from "common/components/PageHeader/PageHeader";
import Table from "common/components/Table/Table";
import TableAvatarTitle from "common/components/TableUtilities/TableAvatarTitle/TableAvatarTitle";
import {
  TableDeleteButton,
  TableEditButton,
} from "common/components/TableUtilities/TableButtons/TableButtons";
// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "common/components/Button/Button";
import { BadgeCheckIcon } from "@heroicons/react/outline";

function Users() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    itemsPerPage,
    itemsCount,
    setPageIndex,
    error,
    pageIndex,
    removeListItem,
  } = useList("rides/riders", {
    defaultFilters: {},
    defaultSort: "username:ASC",
  });
  const { removeItem, updateItem } = useItem("users", "");

  const { navigate } = useRouter();
  // const { isAdmin } = useAuth();

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );

  // const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      // setAlert(error, 'danger');
    }
  }, [error /* setAlert */]);

  const columns = useMemo(
    () => [
      {
        Header: t("common.username"),
        filterName: "username$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { id, username, blocked, confirmed },
          },
        }: any) => (
          <TableAvatarTitle
            title={username}
            link={`/users/edit/${id}`}
            isBlocked={blocked}
            isConfirmed={confirmed}
          />
        ),
      },
      {
        Header: "email",
        filterName: "email$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { email },
          },
        }: any) => <a href={`mailto:${email}`}>{email}</a>,
      },
      {
        Header: "cilipoints",
        filterName: "cilipoints$eq",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { cilipoints },
          },
        }: any) => <span>{cilipoints}</span>,
      },
      {
        Header: t("common.company"),
        filterName: "company][name$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { company },
          },
        }: any) => <span>{company?.name}</span>,
      },
      {
        Header: "Trajets en conducteur",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { ridesWhereDriver },
          },
        }: any) => <span>{ridesWhereDriver}</span>,
      },
      {
        Header: "Trajets en passager",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { ridesWherePassenger },
          },
        }: any) => <span>{ridesWherePassenger}</span>,
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        // eslint-disable-next-line
        Cell: ({ row: { original } }: any) => {
          // if (!isAdmin) return null;

          return (
            <div className="flex justify-end">
              {!original?.confirmed && (
                <Button
                  icon={
                    <BadgeCheckIcon className="h-5 w-5" aria-hidden="true" />
                  }
                  // transparent
                  className="mr-2 bg-green-500 hover:bg-green-600 px-1 py-1"
                  onClick={() => {
                    const updatedItem = { ...original, confirmed: true };
                    updateItem(updatedItem.id, updatedItem, false).then(() =>
                      fetchItems()
                    );
                  }}
                  title="Confirm user"
                />
              )}
              <TableEditButton
                onClick={() => navigate(`/users/edit/${original.id}`)}
              />
              <TableDeleteButton onClick={() => handleDelete(original.id)} />
            </div>
          );
        },
      },
    ],
    [t, updateItem, fetchItems, navigate, handleDelete]
  );

  return (
    <>
      <PageHeader
        title={t("common.users")}
        /* link={{ url: "/users/add", icon: "plus", label: t("common.add") }}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }} */
      />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            itemsPerPage={itemsPerPage}
            itemsCount={itemsCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
          />
        </section>
      </main>
    </>
  );
}

export default Users;
