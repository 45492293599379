/* eslint-disable jsx-a11y/control-has-associated-label */
import { useTranslation } from "react-i18next";

import { CalendarIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import Button from "common/components/Button/Button";
import { useCallback } from "react";
import useItem from "common/hooks/useItem";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function ReportDetail({ data, fetchItem }: any) {
  const { t } = useTranslation();

  const { updateItem }: any = useItem("users", 0);

  const handleBlock = useCallback(
    async (userItem: any) => {
      const updatedItem = { blocked: true };
      await updateItem(userItem.id, updatedItem, false);
      fetchItem();
    },
    [updateItem, fetchItem]
  );

  const handleUnblock = useCallback(
    async (userItem: any) => {
      const updatedItem = { blocked: false };
      await updateItem(userItem.id, updatedItem, false);
      fetchItem();
    },
    [updateItem, fetchItem]
  );

  if (!data) {
    return null;
  }

  const items = [
    { name: t("reports.reporting"), value: data.userReporting.username },
    {
      name: t("reports.reported"),
      value: data.userReported.username,
      button: true,
    },
    { name: "message", value: data.commentFromUserReporting },
  ];

  return (
    <div>
      <dl className="m-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {items.map((item) => (
          <div
            key={item.name}
            className="px-4 py-5 bg-white overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-xl font-semibold text-gray-900">
              <span
                className={
                  item.button && data.userReported.blocked ? "line-through" : ""
                }
              >
                {item.value}{" "}
              </span>
              {item.button && (
                <Button
                  className="bg-red-600 hover:bg-red-500"
                  label={
                    data.userReported.blocked
                      ? t("common.unban")
                      : t("common.ban")
                  }
                  onClick={() => {
                    if (data.userReported.blocked) {
                      handleUnblock(data.userReported);
                    } else {
                      handleBlock(data.userReported);
                    }
                  }}
                  icon={null}
                />
              )}
            </dd>
          </div>
        ))}
      </dl>

      <div className="m-5 bg-white p-5 ">
        {data.message?.conversation?.messages?.map((message: any) => (
          <div
            key={message.id}
            className="flex text-sm text-gray-500 space-x-4"
          >
            <div
              className={classNames(
                message.id === 0 ? "" : "border-t border-gray-200",
                "flex-1 py-3"
              )}
            >
              <div className="flex flex-row">
                <p className="font-bold">{message.sender.username}</p>
                <p className="text-xs italic flex flex-row">
                  <CalendarIcon className="ml-2 h-4 w-4 mr-1" />
                  {dayjs(message.sender.createdAt).format("DD/MM/YYYY hh:mm")}
                </p>
              </div>

              <div
                className={classNames(
                  "px-2 mt-4 prose prose-sm max-w-none text-gray-500",
                  data.message.id === message.id ? "bg-red-300" : ""
                )}
              >
                {message?.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReportDetail;

/*
          if (data.comment) {
            return (
              <div>
                <p> {data.comment?.content} </p>
                <p>
                  {" "}
                  de {data.comment?.feed?.ride?.departureLocation} à{" "}
                  {data.comment?.feed?.ride?.arrivalLocation} numero{" "}
                  {data.comment.feed?.ride.id}
                </p>
                <p> commentaire signaler:{data.comment.content}</p>
              </div>
            );
          }

          if (data.notation) {
            return (
              <div>
                <p>
                  de {data.notation?.ride.departureLocation} à{" "}
                  {data.notation?.ride?.arrivalLocation} numero{" "}
                  {data.notation?.ride?.id}
                </p>
                <p> note: {data.notation?.mark}</p>
                <p> commentaire: {data.notation?.comment}</p>
              </div>
            );
          }
          */
