import i18n from "i18next";
import { initReactI18next, TFunction } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import "dayjs/locale/en";
import "dayjs/locale/fr";

// import translationEN from './translations/en.json';
import translationFR from "./translations/fr.json";

dayjs.extend(utc);

export const defaultNS = "translation";

export const resources = {
  fr: {
    translation: translationFR,
  },
} as const;

i18n
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "fr",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ["translation"],
    defaultNS,
    resources,
  });

export default i18n;

export type I18nKey = Parameters<TFunction>[0];
