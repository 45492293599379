import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import Async from "react-select/async";

/**
 * Override of React Select :
 * - return option.value instead of option (obj) (works also with multiple values instead of array of option)
 *   To be able to initialize more quickly a select in a form
 * - onChange will returns also the full option (row) as second arguments, onChange(opt.value,opt)
 * - Default label
 * - Clearable by default, except if required
 * - Default NoResult Message (intl)
 * - Default label Message (intl)
 * - Close Menu on Select except if Multiple
 * - disabled and multiple props instea of multi and isDisabled for more common name
 */
type AsyncType = React.ComponentProps<Async>;

export type Props = {
  loadOptions?: AsyncType["loadOptions"];
  value?: any;
  children?: JSX.Element;
  placeholder?: string;
  onChange?: (val: any, full: any) => void;
  multiple?: boolean;
  clearable?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options?: any[];
  defaultOptions?: any[];
  isFilter?: boolean;
  "data-cy"?: string;
};

function Select({
  value,
  onChange = () => {},
  multiple = false,
  loadOptions,
  clearable = false,
  disabled = false,
  required = false,
  options = [],
  placeholder,
  defaultOptions,
  children,
  isFilter = false,
  "data-cy": dataCy,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (val: any) => {
      let v;

      if (!val) {
        v = val;
      } else if (Array.isArray(val)) {
        v = val.map((item) => item.value);
      } else {
        v = val.value;
      }

      if (multiple && !Array.isArray(v)) {
        v = [v];
      }

      onChange(v, val);
    },
    [onChange, multiple]
  );

  const selectedOption = useMemo(() => {
    if (!value) return value;

    const opts = options || defaultOptions || [];

    if (multiple) {
      return opts.filter((opt) => value.includes(opt.value));
    }

    return opts.find((opt) => opt.value === value);
  }, [value, multiple, options, defaultOptions]);

  let isClearable = clearable;

  if (required) {
    isClearable = false;
  }

  const customSelectStyles = {
    container: (base: any) => ({
      ...base,
      flex: 1,
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 100, minWidth: "180px" }),
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: isFilter ? "#282735" : "#fff",
      borderRadius: 4,
      borderWidth: 1,
      borderColor: state.isFocused
        ? "#009ad4"
        : isFilter
        ? "#4c4b5c"
        : "#4c4b5c",
      "&:hover": {
        borderColor: state.isFocused ? "#009ad4" : "#4c4b5c",
      },
    }),
    dropdownIndicator: (base: any, state: { isFocused: any }) => ({
      ...base,
      color: state.isFocused ? "#009ad4" : isFilter ? "#F5F5F5" : "#282735",
    }),
    placeholder: (base: any) => ({
      ...base,
      color: isFilter ? "#F5F5F5" : "#282735",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: isFilter ? "#F5F5F5" : "#282735",
    }),
    option: (base: any, state: { isSelected: any }) => ({
      ...base,
      color: state.isSelected ? "white" : "#1d1c26",
      "&:hover": {
        backgroundColor: "#009ad4",
        color: "#f5f5f5",
      },
    }),
  };

  const Component = loadOptions ? Async : ReactSelect;

  return (
    <Component
      styles={{
        ...customSelectStyles,
        menuPortal: (base) => ({ ...base, zIndex: 10 }),
      }}
      menuPortalTarget={document.body}
      loadOptions={loadOptions}
      value={selectedOption}
      onChange={handleChange}
      noOptionsMessage={() => t("common.noResult")}
      loadingMessage={() => t("common.loading")}
      closeMenuOnSelect={!multiple}
      placeholder={placeholder || t("common.search")}
      isMulti={multiple}
      isDisabled={disabled}
      isClearable={isClearable}
      options={options}
      defaultOptions={defaultOptions}
      classNamePrefix="react-select"
      aria-label={dataCy}
      {...rest}
    />
  );
}

export default Select;
