/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import useWindowSize from "common/hooks/useWindowSize";
import React from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/extensions
import Button from "../Button/Button";

// eslint-disable-next-line react/prop-types
function PageHeader({ title, link, button, subLink, extraLink, children }) {
  // const size = useWindowSize();

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex bg-white shadow">
      <div className="flex-1 px-8 py-2 md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-start space-x-5">
          {/*
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-full"
              src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
              alt=""
            />
            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
          </div>
        </div>
  */}
          {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
          <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
            {/*
          <p className="text-sm font-medium text-gray-500">
            Applied for{' '}
            <a href="#" className="text-gray-900">
              Front End Developer
            </a>{' '}
            on <time dateTime="2020-08-25">August 25, 2020</time>
          </p>
      */}
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          {link && (
            <Link
              as="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              to={link.url}
            >
              {link.label}
            </Link>
          )}
        </div>
        {button && (
          <Button
            className={`mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3
            bg-indigo-600 
            hover:bg-indigo-700 
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2
            focus:ring-indigo-500 ${button.className}`}
            icon={button.getIcon()}
            label={button.label}
            onClick={button.onClick}
          />
        )}
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  link: null,
  subLink: null,
  extraLink: null,
  children: null,
  button: null,
};

export default PageHeader;
