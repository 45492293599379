import useAuth from "common/hooks/useAuth";
import useRouter from "common/hooks/use-router";

import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  HomeIcon,
  UsersIcon,
  XIcon,
  TruckIcon,
  DocumentDuplicateIcon,
  DocumentSearchIcon,
  TrendingUpIcon,
  OfficeBuildingIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";

import { Link } from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/solid";
import Logo from "../../../images/ciligo-logo.svg";

const navigation = [
  { name: "menu.statistics", href: "/", icon: HomeIcon, current: false },
  { name: "menu.users", href: "/users", icon: UsersIcon, current: false },
  {
    name: "menu.vehicles",
    href: "/vehicles",
    icon: TruckIcon,
    current: false,
  },
  {
    name: "menu.userDocuments",
    href: "/user-documents",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "menu.vehicleDocuments",
    href: "/vehicle-documents",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "menu.waitingDocuments",
    href: "/waiting-documents",
    icon: DocumentSearchIcon,
    current: false,
  },
  // courses data
  {
    name: "menu.rides",
    href: "/rides",
    icon: TrendingUpIcon,
    current: false,
  },
  {
    name: "menu.companies",
    href: "/companies",
    icon: OfficeBuildingIcon,
    current: false,
  },
  {
    name: "menu.events",
    href: "/events",
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "menu.reports",
    href: "/reports",
    icon: ExclamationIcon,
    current: false,
  },
  // { name: 'Applications', href: '/applications', icon: CalendarIcon, current: false },
  // { name: 'Passengers', href: '/passengers', icon: CalendarIcon, current: false },
  // content data
  // { name: 'Comments', href: '/comments', icon: CalendarIcon, current: false },
  // { name: 'Messages', href: '/messages', icon: CalendarIcon, current: false },
  // users data
  // others data
  // { name: 'Partners', href: '/partners', icon: FolderIcon, current: false },
  // { name: 'Cities', href: '/cities', icon: UsersIcon, current: false },
  // { name: 'menu.statistics', href: '/stats', icon: ChartSquareBarIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// eslint-disable-next-line
function Menu({ sidebarOpen, setSidebarOpen }) {
  const { user, signout } = useAuth();
  const { t } = useTranslation();
  const { location } = useRouter();

  let userNavigation = [];
  if (user?.role.name === "Company") {
    userNavigation.push(navigation[0]);
  } else {
    userNavigation = navigation.map((n) => ({ ...n }));
  }

  const currentNav = userNavigation.find((n) => n.href === location.pathname);
  if (currentNav) {
    currentNav.current = true;
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {t(item.name)}
                      </Link>
                    ))}
                  </nav>
                </div>

                {/*
                <div className="flex-shrink-0 flex bg-gray-700 p-4">
                  <a onClick={() => navigate(`/users/edit/${user?.id}`)} className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{user?.username}</p>
                        <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                      </div>
                    </div>
                  </a>
                </div>
                          */}
                <div className="flex-shrink-0 flex bg-gray-700 p-4">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => signout()}
                  >
                    <span>Déconnexion</span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center justify-between flex-shrink-0 px-4">
              <img className="h-8 w-auto" src={Logo} alt="Ciligo" />

              <button
                type="button"
                className="relative inline-flex items-center px-2 py-2
                border border-transparent text-sm font-medium rounded-full
                text-white bg-gray-600 shadow-sm hover:bg-gray-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                onClick={() => signout()}
              >
                <LogoutIcon className="h-4" />
              </button>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {userNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-300"
                        : "text-gray-400 group-hover:text-gray-300",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {t(item.name)}
                </Link>
              ))}
            </nav>
          </div>

          {/*
          <div className="flex-shrink-0 flex bg-gray-700 p-4">
            <a onClick={() => navigate(`/users/edit/${user?.id}`)} className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">{user?.username}</p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>
                </div>
              </div>
            </a>
          </div>
                    */}
        </div>
      </div>
    </>
  );
}

export default Menu;

/*

    <aside
      data-test="component-aside"
      className={`menu is-dark ${menuActive
        && 'is-open'}`}
    >
      <PositionTop />

      <div className="menu-container">
        {(isAdmin || isManager) ? (
            currentCompany?.id ? (
              <>
                <ManagerMenu />
                <AdminMenu />
              </>
            ) : (
              isAdmin && <AdminMenu />
            )

        ) : (
            currentCompany?.id && <EmployeeMenu />
        )}
        <PositionBottom />
      </div>
    </aside>

    */
