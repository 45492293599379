import Login from "common/pages/Login";
import Error404 from "common/pages/Page404";
import Cities from "pages/Cities/Cities";
import City from "pages/Cities/City";
import Comments from "pages/Comments/Comments";
import Comment from "pages/Comments/Comment";
import Companies from "pages/Companies/Companies";
import Company from "pages/Companies/Company";
import Users from "pages/Users/Users";
import User from "pages/Users/User";
import UserDocuments from "pages/UserDocuments/UserDocuments";
import UserDocument from "pages/UserDocuments/UserDocument";
import VehicleDocuments from "pages/VehicleDocuments/VehicleDocuments";
import VehicleDocument from "pages/VehicleDocuments/VehicleDocument";
import Vehicles from "pages/Vehicles/Vehicles";
import Vehicle from "pages/Vehicles/Vehicle";
import Applications from "pages/Applications/Applications";
import Application from "pages/Applications/Application";
import Rides from "pages/Rides/Rides";
import Ride from "pages/Rides/Ride";
import Stats from "pages/Stats/Stats";
import WaitingDocuments from "pages/WaitingDocuments/WaitingDocuments";
import Reports from "pages/Reports/Reports";
import Report from "pages/Reports/Report";
import Events from "./pages/Event/Events";
import EventPage from "./pages/Event/EventPage";

const managers = ["Admin"];
const all = ["Admin", "Company"];

const routes = [
  { path: "/login", roles: all, Component: Login },
  { path: "/applications", roles: managers, Component: Applications },
  { path: "/applications/add", roles: managers, Component: Application },
  { path: "/applications/edit/:id", roles: managers, Component: Application },
  { path: "/cities", roles: managers, Component: Cities },
  { path: "/cities/add", roles: managers, Component: City },
  { path: "/cities/edit/:id", roles: managers, Component: City },
  { path: "/comments", roles: managers, Component: Comments },
  { path: "/comments/add", roles: managers, Component: Comment },
  { path: "/comments/edit/:id", roles: managers, Component: Comment },
  { path: "/companies", roles: managers, Component: Companies },
  { path: "/companies/add", roles: managers, Component: Company },
  { path: "/companies/edit/:id", roles: managers, Component: Company },
  { path: "/events/", roles: managers, Component: Events },
  { path: "/events/add", roles: managers, Component: EventPage },
  { path: "/events/edit/:id", roles: managers, Component: EventPage },
  { path: "/rides", roles: managers, Component: Rides },
  { path: "/rides/add", roles: managers, Component: Ride },
  { path: "/rides/edit/:id", roles: managers, Component: Ride },
  { path: "/users", roles: managers, Component: Users },
  { path: "/users/add", roles: managers, Component: User },
  { path: "/users/edit/:id", roles: managers, Component: User },
  { path: "/user-documents", roles: managers, Component: UserDocuments },
  { path: "/user-documents/add", roles: managers, Component: UserDocument },
  {
    path: "/user-documents/edit/:id",
    roles: managers,
    Component: UserDocument,
  },
  { path: "/vehicle-documents", roles: managers, Component: VehicleDocuments },
  {
    path: "/vehicle-documents/add",
    roles: managers,
    Component: VehicleDocument,
  },
  {
    path: "/vehicle-documents/edit/:id",
    roles: managers,
    Component: VehicleDocument,
  },
  { path: "/vehicles", roles: managers, Component: Vehicles },
  { path: "/vehicles/add", roles: managers, Component: Vehicle },
  { path: "/vehicles/edit/:id", roles: managers, Component: Vehicle },
  { path: "/waiting-documents", roles: managers, Component: WaitingDocuments },
  { path: "/reports/", roles: managers, Component: Reports },
  { path: "/reports/:id", roles: all, Component: Report },
  { path: "/", roles: all, Component: Stats },
  { path: "*", exact: false, Component: Error404 },
];

export default routes;
