import { CheckIcon, XIcon } from "@heroicons/react/solid";
import Confirm from "common/components/Confirm/Confirm";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ThumbUpIcon } from "@heroicons/react/outline";

function WaitingUserDocuments() {
  const { t } = useTranslation();
  const [itemSelected, setItemSelected] = useState<any>(null);
  const { fetchItems, items, error } = useList("user-documents", {
    defaultFilters: { status: "in_waiting" },
    defaultSort: "",
    populate: ["user", "document"],
  });
  const { updateItem } = useItem("user-documents", "");

  const handleValidation = useCallback(
    async (item: any) => {
      const updatedItem = {
        ...item,
        validationDate: new Date().toISOString(),
        status: "accepted",
      };
      await updateItem(updatedItem.id, updatedItem);
      fetchItems();
    },
    [updateItem, fetchItems]
  );

  const handleRefusal = useCallback(
    async (item: any) => {
      const updatedItem = {
        ...item,
        refusalDate: new Date().toISOString(),
        status: "refused",
      };
      await updateItem(updatedItem.id, updatedItem);
      fetchItems();
    },
    [updateItem, fetchItems]
  );

  useEffect(() => {
    if (error) {
      // setAlert(error, 'danger');
    }
  }, [error /* setAlert */]);

  const formattedItem = itemSelected ? (
    <div>
      <img
        className="shadow-lg"
        src={ImageFromStrapiMedia(itemSelected?.document).uri}
        alt=""
      />
    </div>
  ) : null;

  return (
    <>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {items && items.length === 0 && (
          <div className="text-center mt-2 text-sm font-medium text-gray-900">
            <ThumbUpIcon className="mx-auto h-12 w-12  text-gray-400" />
            {t("document.validationdone")}
          </div>
        )}
        {items?.map((item: any) => (
          <li
            key={item.id}
            className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="text-gray-900 text-sm font-medium truncate">
                    {item.user.username}
                  </h3>
                </div>
              </div>
            </div>
            <div className="aspect-w-3 aspect-h-2">
              <img
                onClick={() => setItemSelected(item)}
                className="object-cover shadow-lg rounded-lg"
                src={ImageFromStrapiMedia(item.document).uri}
                alt=""
              />
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <button
                    type="button"
                    onClick={() => handleValidation(item)}
                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-white font-medium border border-transparent rounded-bl-lg bg-green-600 hover:bg-green-700"
                  >
                    <CheckIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{t("common.validate")}</span>
                  </button>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <button
                    type="button"
                    onClick={() => handleRefusal(item)}
                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 hover:bg-gray-300"
                  >
                    <XIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{t("common.reject")}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Confirm
        message={formattedItem}
        show={Boolean(itemSelected)}
        confirmModal={() => handleValidation(itemSelected)}
        rejectModal={() => {
          handleRefusal(itemSelected);
        }}
        closeModal={() => {
          setItemSelected(null);
        }}
        maxWidth="max-w-7xl"
        confirmMessage="Validate"
        confirmCancel="Reject"
        confirmColor="green"
        enableIcon={false}
      />
    </>
  );
}

export default WaitingUserDocuments;
