import PageHeader from "common/components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import React, { useEffect, useMemo, useState } from "react";
// import dayjs from "dayjs";
import fetchJSON from "common/utils/fetchJSON";
import ButtonGroup from "common/components/ButtonGroup/ButtonGroup";
import {
  format,
  subMonths,
  isFirstDayOfMonth,
  startOfYear,
  startOfMonth,
  //  addDays,
  startOfWeek,
  //  addMonths,
} from "date-fns";
import { fr as french } from "date-fns/locale";
// import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
// import fr from "date-fns/locale/fr";
import CompanySelect from "common/components/CustomSelect/CompanySelect/CompanySelect";
import useAuth from "common/hooks/useAuth";
import { Company } from "types/Company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faIdCard,
  faUsers,
  faUserCheck,
  faCloud,
  faTachometer,
} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "common/components/DatePicker/DateRangePicker";

function Stats() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const maxDate = new Date();
  const defaultStartDate = startOfMonth(subMonths(maxDate, 1));
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);
  const [company, setCompany] = useState<number | string | undefined>(
    currentUser && currentUser?.role.type !== "admin"
      ? (currentUser?.company as unknown as Company)?.id
      : undefined
  );
  const [step, setStep] = useState("day");
  const [data, setData] = useState<any>({});
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [participation, setParticipation] = useState<string>(
    "ridesWhereDriverOrOnePassengerAreFromCompany"
  );

  const buildUrl = useMemo(() => {
    if (startDate && endDate) {
      if (company) {
        return `rides/stats/${company}/${format(
          startDate as Date,
          "yyyy-MM-dd"
        )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
      }
      return `rides/stats/${format(startDate as Date, "yyyy-MM-dd")}/${format(
        endDate as Date,
        "yyyy-MM-dd"
      )}/${step}`;
    }
    return null;
  }, [company, startDate, endDate, step]);

  useEffect(() => {
    if (buildUrl) {
      const getData = async () => {
        setIsFetching(true);
        const res = await fetchJSON({
          url: buildUrl,
          method: "GET",
        });
        setIsFetching(false);
        setData(res);
      };

      getData();
    }
  }, [company, startDate, endDate, step, buildUrl]);

  const generalData = useMemo(() => {
    return {
      nbUsers: data?.general?.users,
      nbDrivers: data?.general?.drivers,
      nbPassengers: data?.general?.passengers,
      nbRidesCarPooled: data?.general?.ridesCarPooled,
      nbRides: data?.general?.rides,
      nbKm: data?.general?.nbKm,
      nbCO2: data?.general?.nbCO2,
    };
  }, [data]);

  const series = useMemo(() => {
    if (company) {
      return {
        waiting: {
          name: t("stats.nbRidesNotStarted"),
          data: (data?.filtered || []).map((stepData: any) => {
            return (
              stepData[participation].rides - stepData[participation].ridesDone
            );
          }),
        },
        noApplicantNoPassenger: {
          name: t("stats.nbRidesCreatedAlone"),
          data: (data?.filtered || []).map(
            (stepData: any) =>
              stepData[participation].rides -
              stepData[participation].ridesWithAtLeastOneApplicant -
              stepData[participation].ridesWithAtLeastOnePassenger
          ),
        },
        withApplication: {
          name: t("stats.nbRidesWithApplicants"),
          data: (data?.filtered || []).map(
            (stepData: any) =>
              stepData[participation].ridesWithAtLeastOneApplicant
          ),
        },
        withPassenger: {
          name: t("stats.nbRidesWithPassengers"),
          data: (data?.filtered || []).map(
            (stepData: any) =>
              stepData[participation].ridesWithAtLeastOnePassenger
          ),
        },
        done: {
          name: t("stats.nbRidesDone"),
          data: (data?.filtered || []).map(
            (stepData: any) => stepData[participation].ridesDone
          ),
        },
      };
    }
    return {
      waiting: {
        name: t("stats.nbRidesNotStarted"),
        data: (data?.filtered || []).map(
          (stepData: any) =>
            stepData.ridesForAllCompanies.rides -
            stepData.ridesForAllCompanies.ridesDone
        ),
      },
      noApplicantNoPassenger: {
        name: t("stats.nbRidesCreatedAlone"),
        data: (data?.filtered || []).map(
          (stepData: any) =>
            stepData.ridesForAllCompanies.rides -
            stepData.ridesForAllCompanies.ridesWithAtLeastOneApplicant -
            stepData.ridesForAllCompanies.ridesWithAtLeastOnePassenger
        ),
      },
      withApplication: {
        name: t("stats.nbRidesWithApplicants"),
        data: (data?.filtered || []).map(
          (stepData: any) =>
            stepData.ridesForAllCompanies.ridesWithAtLeastOneApplicant
        ),
      },
      withPassenger: {
        name: t("stats.nbRidesWithPassengers"),
        data: (data?.filtered || []).map(
          (stepData: any) =>
            stepData.ridesForAllCompanies.ridesWithAtLeastOnePassenger
        ),
      },
      done: {
        name: t("stats.nbRidesDone"),
        data: (data?.filtered || []).map(
          (stepData: any) => stepData.ridesForAllCompanies.ridesDone
        ),
      },
    };
  }, [company, data, participation, t]);

  const stats = useMemo(
    () => [
      {
        name: t("stats.nbUsers"),
        icon: (
          <FontAwesomeIcon
            icon={faUsers}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: generalData.nbUsers ?? 0,
      },
      {
        name: t("stats.nbDrivers"),
        icon: (
          <FontAwesomeIcon
            icon={faIdCard}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: generalData.nbDrivers ?? 0,
      },
      {
        name: t("stats.nbPassengers"),
        icon: (
          <FontAwesomeIcon
            icon={faUserCheck}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: generalData.nbPassengers ?? 0,
      },
      {
        name: t("stats.nbKm"),
        icon: (
          <FontAwesomeIcon
            icon={faTachometer}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: generalData.nbKm ?? 0,
      },
      {
        name: t("stats.nbCO2"),
        icon: (
          <FontAwesomeIcon
            icon={faCloud}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: generalData.nbCO2 ?? 0,
      },
      {
        name: `${t("stats.nbRidesCreated")}`, // / ${t("stats.nbRidesCarPooled")}`,
        icon: (
          <FontAwesomeIcon
            icon={faCar}
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat: `${generalData.nbRides ?? 0}`,
      },
    ],
    [generalData, t]
  );

  return (
    <>
      <PageHeader title={t("titlePages.stats")} />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="flex flex-row justify-around justify-items-center my-3">
            <div className="mx-4">
              <CompanySelect
                value={company}
                onChange={(c: any) => {
                  setData({});
                  setCompany(c);
                }}
                placeholder={t("stats.choseCompany")}
                disabled={currentUser?.role.type !== "admin"}
                clearable
              />
            </div>
          </div>

          <div className="flex my-3" />

          <h3 className="flex flex-row justify-around justify-items-center text-lg leading-6 font-medium text-gray-900 font-bold">
            {company
              ? t("stats.generalCompanyInfoTitle")
              : t("stats.generalInfoTitle")}
          </h3>

          <dl className="mt-3 grid md:grid-rows-3 md:grid-flow-col gap-3 mb-10">
            {stats.map((item) => (
              <div
                key={item.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </p>
                </dd>
              </div>
            ))}
          </dl>

          <div className="flex flex-row justify-around justify-items-center my-5">
            <div className="flex flex-row items-center gap-2">
              <div>{t("stats.dates")}</div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartChange={setStartDate}
                onEndChange={setEndDate}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              {t("stats.scale")}
              <ButtonGroup
                value={step}
                onChange={setStep}
                multiple={false}
                clearable={false}
                disabled={false}
                required
                options={[
                  { value: "month", label: t("stats.month") },
                  { value: "week", label: t("stats.week") },
                  { value: "day", label: t("stats.day") },
                ]}
                className="stepChoice"
              />
            </div>
          </div>

          {startDate && endDate && (
            <div className="flex flex-row justify-around justify-items-center my-3 font-bold">
              {step === "day" &&
                `${t("stats.fromToDay", {
                  from: format(startDate, "dd/MM/yyyy"),
                  to: format(endDate, "dd/MM/yyyy"),
                })} :`}
              {step === "week" &&
                `${t("stats.fromToWeek", {
                  from: format(
                    startOfWeek(startDate, { locale: french }),
                    "EEEE dd/MM/yyyy",
                    { locale: french }
                  ),
                  to: format(
                    startOfWeek(endDate, { locale: french }),
                    "EEEE dd/MM/yyyy",
                    { locale: french }
                  ),
                })} :`}
              {step === "month" &&
                `${t("stats.fromMonth", {
                  from: format(startDate, "MMMM yyyy", { locale: french }),
                  to: format(endDate, "MMMM yyyy", { locale: french }),
                })} :`}
            </div>
          )}

          <div className="xl:container xl:mx-auto columns-xl">
            {!isFetching && data?.filtered && (
              <div id="chart-rides" className="">
                <Chart
                  options={{
                    chart: {
                      id: "rides",
                      stacked: true,
                    },
                    colors: ["#FF9800", "#4CAF50"],
                    fill: {
                      colors: ["#FF9800", "#4CAF50"],
                    },
                    xaxis: {
                      categories:
                        data?.filtered.map((stepData: any) => {
                          const d = new Date(stepData.date[0]);
                          if (startOfYear(d) === d) {
                            return format(d, "yyyy-MMM-dd");
                          }
                          if (isFirstDayOfMonth(d)) {
                            return format(d, "MMM-dd", { locale: french });
                          }
                          return format(d, "dd");
                        }) || [],
                    },
                  }}
                  series={data ? [series.waiting, series.done] : []}
                  type="bar"
                />
              </div>
            )}
            {!isFetching && data?.filtered && (
              <div id="chart-application" className="">
                <Chart
                  options={{
                    chart: {
                      id: "ridesDone",
                      stacked: true,
                    },
                    colors: ["#C4BBAF", "#F9A3A4", "#43BCCD"],
                    fill: {
                      colors: ["#C4BBAF", "#F9A3A4", "#43BCCD"],
                    },
                    xaxis: {
                      categories:
                        data?.filtered.map((stepData: any) => {
                          const d = new Date(stepData.date[0]);
                          if (startOfYear(d) === d) {
                            return format(d, "yyyy-MMM-dd");
                          }
                          if (isFirstDayOfMonth(d)) {
                            return format(d, "MMM-dd", { locale: french });
                          }
                          return format(d, "dd");
                        }) || [],
                    },
                  }}
                  series={
                    data
                      ? [
                          series.noApplicantNoPassenger,
                          series.withApplication,
                          series.withPassenger,
                        ]
                      : []
                  }
                  type="bar"
                />
              </div>
            )}
          </div>

          {company && (
            <div className="flex flex-row justify-center justify-items-center my-3">
              <ButtonGroup
                value={participation}
                onChange={setParticipation}
                multiple={false}
                clearable={false}
                disabled={false}
                required={false}
                options={[
                  {
                    value: "ridesWhereDriverOrOnePassengerAreFromCompany",
                    label: t("stats.driverOrPassenger"),
                  },
                  {
                    value: "ridesWhereDriverAndOnePassengerAreFromCompany",
                    label: t("stats.driverAndPassenger"),
                  },
                  {
                    value: "ridesWhereOnlyDriverIsFromCompany",
                    label: t("stats.driverOnly"),
                  },
                  {
                    value: "ridesWhereOnlyPassengerIsFromCompany",
                    label: t("stats.passengerOnly"),
                  },
                ]}
                className="participationChoice"
              />
            </div>
          )}
        </section>
      </main>
    </>
  );
}

export default Stats;
