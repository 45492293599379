import { useRef, useEffect, useState } from "react";

export type Props = {
  value?: string;
  onChange?: (val: any) => void;
  rows?: number;
  className?: string;
};

function TextArea({ rows = 0, value = "", className, ...props }: Props) {
  const textAreaRef = useRef({} as any);
  const [count, setCount] = useState(rows);
  const [height, setHeight] = useState(5);

  if (textAreaRef.current && textAreaRef.current.scrollHeight !== height) {
    setHeight(textAreaRef.current.scrollHeight);
  }

  useEffect(() => {
    setCount(value.split("\n").length + 1);
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      className={`input ${className}`}
      value={value}
      rows={count + 1}
      style={{ minHeight: 100, height, overflow: "hidden" }}
      {...props}
    />
  );
}

export default TextArea;
