import CompanySelect from "common/components/CustomSelect/CompanySelect/CompanySelect";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

function EventForm({ onSubmit, event }: any) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () =>
      event?.id
        ? {
            ...event,
            company: event?.company?.id,
          }
        : {
            name: "",
          },
    [event]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />

            <FormControl title={t("common.eventName")} name="name" required />
            <FormControl title={t("common.address")} name="address" required />
            <FormControl
              name="date"
              titleKey={t("common.date")}
              type="date"
              required
            />
            <FormControl
              name="mainPicture"
              titleKey={t("forms.mainPicture")}
              type="image"
              maxWidth={400}
              maxHeight={400}
            />
            <FormControl
              name="backgroundPicture"
              titleKey={t("forms.backgroundPicture")}
              type="image"
              maxWidth={1600}
              maxHeight={800}
            />
            <FormControl
              name="otherMedia"
              titleKey={t("forms.otherMedia")}
              type="image"
              maxWidth={1200}
              maxHeight={1600}
            />

            <FormControl
              type="textarea"
              title={t("event.description")}
              name="description"
            />
            <FormControl
              type="custom"
              name="company"
              titleKey={t("forms.company")}
            >
              <CompanySelect />
            </FormControl>

            <FormControl title={t("common.link")} name="link" />
            <FormControl
              title={`${t("common.link")} Facebook`}
              name="facebookLink"
            />
            <FormControl
              title={`${t("common.link")} Instagram`}
              name="instagramLink"
            />
            <FormControl
              title={`${t("common.link")} Twitter`}
              name="twitterLink"
            />
          </section>
          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default EventForm;
