// import Card from 'common/components/Card/Card';
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import UserSelect from "common/components/CustomSelect/UserSelect/UserSelect";
import RideSelect from "common/components/CustomSelect/RideSelect/RideSelect";
import SectionSelect from "common/components/CustomSelect/SectionSelect/SectionSelect";
import FeedSelect from "common/components/CustomSelect/FeedSelect/FeedSelect";
import VehicleSelect from "common/components/CustomSelect/VehicleSelect/VehicleSelect";
import ApplicationSelect from "common/components/CustomSelect/ApplicationSelect/ApplicationSelect";
import PassengerSelect from "common/components/CustomSelect/PassengerSelect/PassengerSelect";
import CitySelect from "common/components/CustomSelect/CitySelect/CitySelect";
// import useAuth from 'common/contexts/auth';
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
// import CompanySelect from 'requests/components/CompanySelect';
// import useCompanies from 'requests/contexts/companies';

const status = [
  {
    value: "incoming",
    label: "common.incoming",
  },
  {
    value: "in_progress",
    label: "common.in_progress",
  },
  {
    value: "finished",
    label: "common.finished",
  },
];

const valuesYesNo: any[] = [
  { value: "1", label: "common.yes" },
  { value: "0", label: "common.no" },
];

function RideForm({ onSubmit, data }: any) {
  const { t } = useTranslation();
  // const { user: currentUser } = useAuth();
  // const { currentCompany } = useCompanies();

  const initialValues = useMemo(
    () =>
      data?.id
        ? {
            ...data,
            user: data.user.id,
            ride: data.ride.id,
            section: data.section.id,
          }
        : {
            name: "",
          },
    [data /* currentCompany */]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />
            <FormControl
              type="custom"
              name="user"
              title={t("common.driver")}
              required
            >
              <UserSelect />
            </FormControl>
            <FormControl
              type="custom"
              name="feeds"
              title={t("common.feeds")}
              required
            >
              <FeedSelect multiple="true" />
            </FormControl>
            <FormControl
              name="price"
              title={t("common.price")}
              type="number"
              required
            />
            <FormControl
              type="custom"
              name="vehicle"
              title={t("common.vehicle")}
              required
            >
              <VehicleSelect />
            </FormControl>
            <FormControl
              type="date"
              name="departureTime"
              title={t("common.departureTime")}
            />
            <FormControl
              type="date"
              name="arrivalTime"
              title={t("common.arrivalTime")}
            />
            <FormControl name="code" title={t("common.code")} required />
            <FormControl name="seats" title={t("common.seats")} required />
            <FormControl
              type="select"
              name="smoking"
              title={t("common.smoking")}
              options={valuesYesNo}
            />
            <FormControl
              type="select"
              name="pets"
              title={t("common.pets")}
              options={valuesYesNo}
            />
            <FormControl
              type="select"
              name="vaccinationPass"
              title={t("common.vaccinationPass")}
              options={valuesYesNo}
            />
            <FormControl
              name="distance"
              type="number"
              title={t("common.distance")}
              required
            />
            <FormControl
              type="custom"
              name="sections"
              title={t("common.sections")}
              required
            >
              <SectionSelect multiple="true" />
            </FormControl>
            <FormControl
              name="status"
              title={t("common.status")}
              type="select"
              options={status}
              required
            />
            <FormControl
              type="select"
              name="full"
              title={t("common.full")}
              options={valuesYesNo}
            />
            <FormControl
              type="custom"
              name="applications"
              title={t("common.applications")}
              required
            >
              <ApplicationSelect multiple="true" />
            </FormControl>
            <FormControl
              type="custom"
              name="passengers"
              title={t("common.passengers")}
              required
            >
              <PassengerSelect multiple="true" />
            </FormControl>
            <FormControl
              type="custom"
              name="departureLocation"
              title={t("common.departureLocation")}
              required
            >
              <CitySelect />
            </FormControl>
            <FormControl
              type="custom"
              name="ArrivalLocation"
              title={t("common.ArrivalLocation")}
              required
            >
              <CitySelect />
            </FormControl>
            <FormControl name="otherDetails" title={t("common.otherDetails")} />
            <FormControl
              type="select"
              name="isRecurrent"
              title={t("common.isRecurrent")}
              options={valuesYesNo}
            />
            <FormControl
              type="custom"
              name="parent"
              title={t("common.parent")}
              required
            >
              <RideSelect />
            </FormControl>
            <FormControl
              name="recurrentDays"
              title={t("common.recurrentDays")}
            />
            <FormControl
              type="date"
              name="recurrentCreationDate"
              title={t("common.recurrentCreationDate")}
            />
            <FormControl
              name="intermediateLocations"
              title={t("common.intermediateLocations")}
            />
            <FormControl
              name="sectionDetail"
              title={t("common.sectionDetail")}
            />
          </section>

          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default RideForm;
