import PageHeader from "common/components/PageHeader/PageHeader";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useTranslation } from "react-i18next";
import ReportDetail from "./ReportDetail";

const urlLabel = "reports";

function Report() {
  const { t } = useTranslation();
  const router = useRouter();

  const id = router.query.id as string;

  const { item, isFetching, fetchItem }: any = useItem(urlLabel, id, {
    populate: [
      "userReported",
      "userReported.profilePicture",
      "userReported.company",
      "userReporting",
      "userReporting.profilePicture",
      "userReporting.company",
      "message",
      "message.sender",
      "message.receiver",
      "message.conversation",
      "message.conversation.messages",
      "message.conversation.messages.sender",
      "message.conversation.messages.receiver",
      "message.conversation.users",
      "comment",
      "comment.user",
      "comment.feed",
      "comment.likes",
      "comment.responseOf",
      "comment.feed.comments",
      "comment.feed.ride",
      "comment.feed.ride.passengers",
      "comment.feed.ride.passengers.user",
      "comment.feed.ride.passengers.user.profilePicture",
      "comment.feed.ride.passengers.user.company",
      "comment.feed.ride.applications",
      "comment.feed.ride.applications.user",
      "comment.feed.ride.applications.user.profilePicture",
      "comment.feed.ride.applications.user.company",
      "comment.feed.ride.driver",
      "comment.feed.ride.vehicle",
      "comment.feed.ride.departureLocation",
      "comment.feed.ride.arrivalLocation",
      "notation",
      "notation.userAuthor",
      "notation.userAuthor.profilePicture",
      "notation.userAuthor.company",
      "notation.userTarget",
      "notation.userTarget.profilePicture",
      "notation.userTarget.company",
      "notation.ride",
      "notation.ride.passengers",
      "notation.ride.passengers.user",
      "notation.ride.passengers.user.profilePicture",
      "notation.ride.passengers.user.company",
      "notation.ride.applications",
      "notation.ride.applications.user",
      "notation.ride.applications.user.profilePicture",
      "notation.ride.applications.user.company",
      "notation.ride.driver",
      "notation.ride.vehicle",
      "notation.ride.departureLocation",
      "notation.ride.arrivalLocation",
    ],
  });
  return (
    <>
      <PageHeader
        title={
          item?.message
            ? "message"
            : item?.notation
            ? "notation"
            : item?.comment
            ? "comment"
            : "no-type"
        }
        subLink={{
          url: `/${urlLabel}`,
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      {}
      {isFetching ? null : (
        <section className="section form-page has-cards">
          <ReportDetail data={item} fetchItem={fetchItem} />
        </section>
      )}
    </>
  );
}
export default Report;
