// import Card from 'common/components/Card/Card';
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import UserSelect from "common/components/CustomSelect/UserSelect/UserSelect";
// import useAuth from 'common/contexts/auth';
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
// import CompanySelect from 'requests/components/CompanySelect';
// import useCompanies from 'requests/contexts/companies';

function VehicleForm({ onSubmit, vehicle }: any) {
  const { t } = useTranslation();
  // const { user: currentUser } = useAuth();
  // const { currentCompany } = useCompanies();

  const valuesYesNo: any[] = [
    { value: "1", label: t("formOptions.yes") },
    { value: "0", label: t("formOptions.no") },
  ];

  const initialValues = useMemo(
    () =>
      vehicle?.id
        ? {
            ...vehicle,
            user: vehicle.user.id,
          }
        : {
            name: "",
          },
    [vehicle /* currentCompany */]
  );

  const status = [
    {
      value: "refused",
      label: t("common.refused"),
    },
    {
      value: "in_waiting",
      label: t("common.in_waiting"),
    },
    {
      value: "accepted",
      label: t("common.accepted"),
    },
  ];

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />
            {/* !currentCompany && currentUser?.role?.name === 'superadmin' && (
                <FormInput
                  type="custom"
                  name="company"
                  label={t('common.company')}
                >
                  <CompanySelect />
                </FormInput>
                ) */}
            <FormControl name="name" title={t("common.name")} required />
            <FormControl name="seats" title={t("common.seats")} required />
            {/* <FormControl
                  name="picture"
                  title={t('common.picture')}
                  required
              /> */}
            <FormControl
              name="consumption"
              title={t("common.consumption")}
              required
            />
            <FormControl
              name="luggageSize"
              title={t("common.luggageSize")}
              required
            />
            <FormControl
              type="buttongroup"
              name="heatedSeats"
              title={t("common.heatedSeats")}
              options={valuesYesNo}
            />
            <FormControl
              type="buttongroup"
              name="airConditioner"
              title={t("common.airConditioner")}
              options={valuesYesNo}
            />
            <FormControl name="fuel" title={t("common.fuel")} required />
            <FormControl
              type="custom"
              name="user"
              title={t("common.user")}
              required
            >
              <UserSelect />
            </FormControl>
            <FormControl
              name="status"
              title={t("common.status")}
              type="select"
              options={status}
              required
            />
          </section>

          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default VehicleForm;
