import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";

const processOptions = (options) =>
  options.map(({ id }) => ({
    value: id,
    label: id, // Need to be changed
  }));

function ApplicationSelect(props) {
  return (
    <RemoteSelect
      url="applications"
      processOptions={processOptions}
      {...props}
    />
  );
}

export default ApplicationSelect;
