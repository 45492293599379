/* import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import CircleColorPicker from 'common/components/CircleColorPicker/CircleColorPicker';
import ColorPicker from 'common/components/ColorPicker/ColorPicker';
import DatePicker from 'common/components/DatePicker/DatePicker';
import Geolocation from 'common/components/Geolocation/Geolocation';
import IconPicker from 'common/components/IconPicker/IconPicker';
import Select from 'common/components/Select/Select';
import TextArea from 'common/components/TextArea/TextArea';
import TimePicker from 'common/components/TimePicker/TimePicker';
import DateTimePicker from 'common/components/DateTimePicker/DateTimePicker';
import Uploader from 'common/components/Uploader/Uploader'; */
import React, { useCallback } from "react";
import ImageUploader, {
  UploadFilesProps,
} from "common/components/ImageUploader/ImageUploader";
import RichTextArea from "../RichTextArea/RichTextArea";
import Select from "../Select/Select";
import TextArea from "../TextArea/TextArea";
import { SelectableOptionsList } from "../SelectableOptionsList/SelectableOptionsList";
import DebounceInput from "../DebounceInput/DebounceInput";
import DatePicker from "../DatePicker/DatePicker";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
// import CheckboxCardGroup from '../CheckboxCardGroup/CheckboxCardGroup';
// import RichTextarea from '../RichTextArea/RichTextArea';

// const keepEmptyValue = (value: string) => (value === "" ? null : value);

/*
export type InputProps = ImagePickerProps &
  SelectableListProps &
  TextInputProps &
  CardCheckFieldProps & {
    type?: string;
    children?: React.ReactElement;
    onChange?: (value: any) => void;
    value?: any;
  }; */
export type InputProps = UploadFilesProps & {
  name?: string;
  title?: string;
  options?: any;
  type?: string;
  icon?: string;
  children?: any | React.ReactElement;
  onChange?: (value: any) => void;
  value?: any;
  disabled?: boolean;
  className?: string;
};

export function FormInput({
  type,
  icon,
  children,
  onChange,
  ...otherProps
}: InputProps) {
  const className = otherProps.className ? otherProps.className : "";

  const handleInputChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  /* const handleCheckboxChange = useCallback(
    (e: { target: { checked: any } }) => {
      onChange && onChange(e.target.checked);
    },
    [onChange]
  ); */

  // eslint-disable-next-line no-param-reassign
  (otherProps as any).onChange = onChange;

  switch (type) {
    /* case 'submit':
      return (
        <button
          className="button is-submit"
          data-cy="submit"
          type="submit"
          disabled={otherProps.disabled}
        >
          {!otherProps.isSubmitting && icon && (
            <span className="icon">
              <i className={`fas fa-${icon}`} />
            </span>
          )}
          {
            otherProps.isSubmitting && (
              <span className="icon icon-loading">
                <i className="fas fa-spinner" />
              </span>
            )
          }
          <span>{otherProps.label}</span>
        </button>
      );

    

    case 'datetime':
      return (<DateTimePicker {...otherProps} />);

    case 'time':
      return (<TimePicker {...otherProps} />);

    case 'file':
      return <Uploader data-cy={otherProps.name} {...otherProps} />;

    case 'geolocation':
      return <Geolocation data-cy={otherProps.name} {...otherProps} />;

      
      case 'checkboxcardgroup':
        return <CheckboxCardGroup data-cy={otherProps.name} {...otherProps} />;
        
        case 'icon':
          return <IconPicker data-cy={otherProps.name} {...otherProps} />;
          
          
          case 'checkbox':
            return (
              <div className="checkbox-input">
              <input
              id={otherProps.name}
              type="checkbox"
              data-cy={otherProps.name}
              {...otherProps}
              onChange={handleCheckboxChange}
              />
              <label htmlFor={otherProps.name}>{otherProps.label}</label>
              </div>
              );
              
              case 'circle-color':
                return <CircleColorPicker {...otherProps} />;
                
                case 'color':
                  return <ColorPicker data-cy={otherProps.name} {...otherProps} />;
                  */
    case "buttongroup":
      return <ButtonGroup data-cy={otherProps.name} {...otherProps} />;

    case "image":
      return (
        <ImageUploader
          data-cy={otherProps.value}
          {...otherProps}
          uploadUrl={undefined}
        />
      );

    case "custom":
      return React.cloneElement(children, {
        ...otherProps,
        "data-cy": otherProps.name,
      });

    case "date":
      return <DatePicker data-cy={otherProps.name} {...otherProps} />;

    case "select":
      return <Select {...otherProps} />;

    case "textarea":
      return <TextArea {...otherProps} />;

    case "richtext":
      return <RichTextArea {...otherProps} />;

    case "select-list":
      return (
        <SelectableOptionsList
          {...(otherProps as React.ComponentProps<
            typeof SelectableOptionsList
          >)}
        />
      );

    default:
      return (
        <DebounceInput
          type={type}
          data-cy={(otherProps as any).name.split(".")[0]}
          {...otherProps}
          onChange={handleInputChange}
          className={className}
        />
      );
  }
}

export default FormInput;
