import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";

const processOptions = (options) =>
  options.map(({ id, username }) => ({
    value: id,
    label: username,
  }));

function UserSelect(props) {
  return (
    <RemoteSelect url="users" processOptions={processOptions} {...props} />
  );
}

export default UserSelect;
