// import Card from 'common/components/Card/Card';
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
// import useAuth from 'common/contexts/auth';
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
// import CompanySelect from 'requests/components/CompanySelect';
// import useCompanies from 'requests/contexts/companies';

function CompanyForm({ onSubmit, company }: any) {
  const { t } = useTranslation();
  // const { user: currentUser } = useAuth();
  // const { currentCompany } = useCompanies();
  const initialValues = useMemo(
    () =>
      company?.id
        ? {
            ...company,
          }
        : {
            name: "",
          },
    [company /* currentCompany */]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />

            {/* !currentCompany && currentUser?.role?.name === 'superadmin' && (
                <FormInput
                  type="custom"
                  name="company"
                  label={t('common.company')}
                >
                  <CompanySelect />
                </FormInput>
                ) */}
            <FormControl name="name" title={t("common.name")} required />
            <FormControl name="code" title={t("common.code")} required />
            <FormControl name="address" title={t("common.address")} required />
            <FormControl
              name="postalCode"
              title={t("common.postalCode")}
              required
            />
            <FormControl name="city" title={t("common.city")} required />
            {/* <FormControl
                  name="logo"
                  title={t('common.logo')}
                  required
                /> */}
          </section>
          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default CompanyForm;
