import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  previousPage: () => void;
  goToPage: (i: number) => void;
  nextPage: () => void;
  currentPage: number;
  totalPage: number;
  disabled: boolean;
};

function Pagination({
  previousPage,
  goToPage,
  nextPage,
  currentPage,
  totalPage,
  disabled,
}: Props) {
  const { t } = useTranslation();
  let buttons = [];

  if (totalPage <= 7) {
    buttons = Array.from({ length: totalPage }).map((l, index) => index);
  } else if (currentPage < 4) {
    buttons = [
      ...Array.from({ length: 5 }).map((l, index) => index),
      -1,
      totalPage - 1,
    ];
  } else if (currentPage > totalPage - 5) {
    buttons = [
      0,
      -1,
      ...Array.from({ length: 5 }).map((l, index) => index + totalPage - 5),
    ];
  } else {
    buttons = [
      0,
      -1,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      -1,
      totalPage - 1,
    ];
  }

  return (
    <nav
      className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      aria-label="Pagination"
    >
      <button
        type="button"
        onClick={() => previousPage()}
        disabled={currentPage === 1 || disabled}
        className="relative inline-flex items-center px-2 py-2 border text-sm font-medium rounded-l-md border-gray-300 bg-white  text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">{t("common.previous")}</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      {buttons.map((i) => {
        const className =
          currentPage === i
            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50";

        if (i === -1) {
          return (
            <span
              key={i}
              className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-700"
            >
              ...
            </span>
          );
        }

        return (
          <button
            key={i}
            type="button"
            disabled={currentPage !== i && disabled}
            onClick={() => goToPage(i)}
            aria-current="page"
            className={classNames(
              className,
              "relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            )}
          >
            {i + 1}
          </button>
        );
      })}
      <button
        type="button"
        onClick={() => nextPage()}
        disabled={currentPage === totalPage || disabled}
        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">{t("common.next")}</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </nav>
  );
}

export default Pagination;
