import PageHeader from "common/components/PageHeader/PageHeader";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BanIcon, BadgeCheckIcon } from "@heroicons/react/solid";
import UserForm from "./UserForm";

function User() {
  const { t } = useTranslation();
  const router = useRouter();
  // const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching, fetchItem }: any =
    useItem("users", id);

  const handleConfirmUser = useCallback(
    async (userItem: any) => {
      const updatedItem = { ...userItem, confirmed: true };
      await updateItem(updatedItem.id, updatedItem, false);
      fetchItem();
    },
    [updateItem, fetchItem]
  );

  const handleBlock = useCallback(
    async (userItem: any) => {
      const updatedItem = { ...userItem, blocked: true };
      await updateItem(updatedItem.id, updatedItem, false);
      fetchItem();
    },
    [updateItem, fetchItem]
  );

  const handleUnblock = useCallback(
    async (userItem: any) => {
      const updatedItem = { ...userItem, blocked: false };
      await updateItem(updatedItem.id, updatedItem, false);
      fetchItem();
    },
    [updateItem, fetchItem]
  );

  const back = useCallback(() => {
    if (!error) {
      // setAlert(t('common.saved'), 'success');
      router.navigate("/users");
    } else {
      // setAlert(error, 'danger');
    }
  }, [/* setAlert */ router, error]);

  const onSubmit = useCallback(
    async (dataUser: any) => {
      const user = { ...dataUser };

      try {
        if (user.id) {
          await updateItem(user.id, user, false);
        } else {
          await saveItem(user, false);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? "edit" : "add"}`)}
        button={
          item?.id &&
          (!item?.confirmed
            ? {
                getIcon: () => (
                  <BadgeCheckIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                ),
                label: t("actions.confirmUser"),
                onClick: () => {
                  handleConfirmUser(item);
                },
                className: "bg-yellow-500 hover:bg-yellow-600",
              }
            : {
                getIcon: () => (
                  <BanIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                ),
                label: item?.blocked ? t("common.unban") : t("common.ban"),
                onClick: () => {
                  if (item?.blocked) {
                    handleUnblock(item);
                  } else {
                    handleBlock(item);
                  }
                },
                className: item?.blocked
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-green-500 hover:bg-green-600",
              })
        }
        subLink={{
          url: "/users",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      {isFetching && id && !item ? null : (
        <UserForm onSubmit={onSubmit} user={item} />
      )}
    </>
  );
}

export default User;
