import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";

const processOptions = (options) =>
  options.map(({ id, price }) => ({
    value: id,
    label: price, // Need to be changed, just for developpment
  }));

function SectionSelect(props) {
  return (
    <RemoteSelect url="sections" processOptions={processOptions} {...props} />
  );
}

export default SectionSelect;
