import CompanySelect from "common/components/CustomSelect/CompanySelect/CompanySelect";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
import useAuth from "common/hooks/useAuth";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

function UserForm({ onSubmit, user }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const valuesSmoker: any[] = [
    { value: true, label: t("forms.smokeDegree") },
    {
      value: false,
      label: t("forms.smokeDegreeNo"),
    },
  ];
  const valuesMusic: any[] = [
    { value: true, label: t("forms.musicDegree") },
    {
      value: false,
      label: t("forms.musicDegreeNo"),
    },
  ];
  const valuesYesNo: any[] = [
    { value: true, label: t("formOptions.yes") },
    { value: false, label: t("formOptions.no") },
  ];
  const valuesPets: any[] = [
    { value: true, label: t("forms.petDegree") },
    { value: false, label: t("forms.petDegreeNo") },
  ];
  const valuesGender: any[] = [
    { value: "W", label: t("formOptions.woman") },
    { value: "M", label: t("formOptions.man") },
  ];
  const valuesRoles: any[] = [
    { value: 1, label: t("formOptions.user") },
    { value: 3, label: t("formOptions.company") },
    { value: 4, label: t("formOptions.admin") },
  ];
  const initialValues = useMemo(
    () =>
      user?.id
        ? {
            ...user,
            reputation: user.reputation || "0",
            role: user.role?.id ?? 1, // Set 'authenticated', by default
            company: user.company?.id,
          }
        : {
            username: "",
            email: "",
            lastname: "",
            cilipoints: "0",
            gender: "",
            phoneNumber: "",
            birthDate: "",
            address: "",
            postalCode: "",
            city: "",
            refferalCode: "",
            reputation: "",
            company: "",
            smoking: "",
            music: "",
            chatting: "",
            pets: "",
            airConditionner: "",
            vaccinationPass: "",
            blocked: "",
            confirmed: "",
          },
    [user]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />
            {}
            <FormControl
              name="profilePicture"
              titleKey={t("forms.avatar")}
              type="image"
            />
            <FormControl
              name="username"
              titleKey={t("forms.username")}
              required
            />
            <FormControl name="email" titleKey={t("forms.email")} required />
            <FormControl name="lastname" titleKey={t("forms.lastName")} />
            {/* <FormControl
              type="buttongroup"
              name="blocked"
              title={t("common.blocked")}
              options={valuesYesNo}
            />
            <FormControl
              type="buttongroup"
              name="confirmed"
              title={t("sentences.profileChecked")}
              options={valuesYesNo}
            /> */}
            <FormControl
              type="buttongroup"
              name="role"
              title={t("common.role")}
              options={valuesRoles}
              editable={currentUser?.role.type === "admin"}
            />
            <FormControl name="cilipoints" title="cilipoints" />
            <FormControl
              type="buttongroup"
              name="gender"
              titleKey={t("forms.gender")}
              options={valuesGender}
            />
            <FormControl name="phoneNumber" titleKey={t("forms.phoneNumber")} />
            <FormControl name="birthDate" titleKey={t("forms.birth")} />
            <FormControl name="address" titleKey={t("forms.address")} />
            <FormControl name="postalCode" titleKey={t("forms.postalCode")} />
            <FormControl name="city" titleKey={t("forms.city")} />
            <FormControl
              name="refferalCode"
              titleKey={t("forms.refferalCode")}
              editable={false}
            />
            <FormControl
              name="reputation"
              titleKey={t("forms.reputation")}
              required
            />
            <FormControl
              type="custom"
              name="company"
              titleKey={t("forms.company")}
            >
              <CompanySelect />
            </FormControl>
            <FormControl
              type="buttongroup"
              name="smoking"
              title={t("forms.smoker")}
              options={valuesSmoker}
            />
            <FormControl
              type="buttongroup"
              name="music"
              title={t("forms.music")}
              options={valuesMusic}
            />
            <FormControl
              type="buttongroup"
              name="chatting"
              title={t("forms.chatting")}
              options={valuesYesNo}
            />
            <FormControl
              type="buttongroup"
              name="pets"
              title={t("forms.pet")}
              options={valuesPets}
            />
            <FormControl
              type="buttongroup"
              name="airConditionner"
              title={t("forms.airConditioner")}
              options={valuesYesNo}
            />
            <FormControl
              type="buttongroup"
              name="vaccinationPass"
              title={t("forms.vaccinationPass")}
              options={valuesYesNo}
            />
          </section>
          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}
export default UserForm;
