/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { useCallback, useEffect, Fragment, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

const Modal = ({
  message,
  confirmModal,
  closeModal,
  rejectModal,
  show,
  maxWidth,
  confirmMessage,
  confirmCancel,
  confirmColor,
  enableIcon,
}) => {
  const { t } = useTranslation();

  const handleEscape = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    },
    [closeModal]
  );

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const keyup = document.addEventListener("keyup", handleEscape);

    return () => document.removeEventListener("keyup", keyup);
  }, [handleEscape]);

  return ReactDOM.createPortal(
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:${maxWidth} sm:w-full sm:p-6`}
              >
                <div className="sm:flex sm:items-start">
                  {enableIcon && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {t("modal.confirmTitle")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                  <button
                    type="button"
                    className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-${confirmColor}-600 text-base font-medium text-white hover:bg-${confirmColor}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${confirmColor}-500 sm:w-auto sm:text-sm`}
                    onClick={confirmModal}
                  >
                    {confirmMessage === "modal.confirm"
                      ? t(confirmMessage)
                      : confirmMessage}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={rejectModal || closeModal}
                    ref={cancelButtonRef}
                  >
                    {confirmCancel === "modal.cancel"
                      ? t(confirmCancel)
                      : confirmCancel}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.getElementById("portal-root")
  );
};

Modal.propTypes = {
  message: PropTypes.any.isRequired,
  confirmModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  maxWidth: "max-w-lg",
  confirmMessage: "modal.confirm",
  confirmCancel: "modal.cancel",
  confirmColor: "red",
  rejectModal: null,
  enableIcon: true,
};

export default Modal;
