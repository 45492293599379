// import Card from 'common/components/Card/Card';
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
// import useAuth from 'common/contexts/auth';
import React, { useMemo } from "react";
import { Form } from "react-final-form";
// import CompanySelect from 'requests/components/CompanySelect';
// import useCompanies from 'requests/contexts/companies';

function UserDocumentForm({ onSubmit, item }: any) {
  // const { user: currentUser } = useAuth();
  // const { currentCompany } = useCompanies();
  const initialValues = useMemo(
    () =>
      item?.id
        ? {
            ...item,
            role: item?.company?.id,
            company: item?.company?.id,
          }
        : {},
    [item /* currentCompany */]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />
            {/* !currentCompany && currentUser?.role?.name === 'superadmin' && (
                <FormInput
                  type="custom"
                  name="company"
                  label={t('common.company')}
                >
                  <CompanySelect />
                </FormInput>
                ) */}
          </section>
          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default UserDocumentForm;
