// import Loader from 'common/components/Loader/Loader';
import PageHeader from "common/components/PageHeader/PageHeader";
// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ApplicationForm from "./ApplicationForm";

const urlLabel = "applications";

function Application() {
  const { t } = useTranslation();
  const router = useRouter();
  // const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    urlLabel,
    id
  );

  const back = useCallback(() => {
    if (!error) {
      // setAlert(t('common.saved'), 'success');
      router.navigate(`/${urlLabel}`);
    } else {
      // setAlert(error, 'danger');
    }
  }, [/* setAlert */ router, error]);

  const onSubmit = useCallback(
    async (payload: any) => {
      const data = { ...payload };

      try {
        if (data.id) {
          await updateItem(data.id, data);
        } else {
          await saveItem(data);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? "edit" : "add"}`)}
        subLink={{
          url: `/${urlLabel}`,
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      {/* <Loader isScreen processing={isFetching} /> */}
      {isFetching && id && !item ? null : (
        <ApplicationForm onSubmit={onSubmit} data={item} />
      )}
    </>
  );
}

export default Application;
