import PageHeader from "common/components/PageHeader/PageHeader";
import Table from "common/components/Table/Table";
import TableAvatarTitle from "common/components/TableUtilities/TableAvatarTitle/TableAvatarTitle";
import {
  TableDeleteButton,
  TableEditButton,
} from "common/components/TableUtilities/TableButtons/TableButtons";
// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

function Companies() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    itemsCount,
    itemsPerPage,
    error,
    pageIndex,
    removeListItem,
  } = useList("companies", { defaultFilters: {}, defaultSort: "name:ASC" });
  const { removeItem } = useItem("companies", "");

  const { navigate } = useRouter();
  // const { isAdmin } = useAuth();

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );

  // const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      // setAlert(error, 'danger');
    }
  }, [error /* setAlert */]);

  const columns = useMemo(
    () => [
      {
        Header: t("common.name"),
        filterName: "name$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { id, name },
          },
        }: any) => (
          <TableAvatarTitle title={name} link={`/companies/edit/${id}`} />
        ),
      },
      {
        Header: t("common.code"),
        filterName: "code$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { code },
          },
        }: any) => <span>{code}</span>,
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        // eslint-disable-next-line
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => {
          // if (!isAdmin) return null;

          return (
            <div className="flex justify-end">
              <TableEditButton
                onClick={() => navigate(`/companies/edit/${id}`)}
              />
              <TableDeleteButton onClick={() => handleDelete(id)} />
            </div>
          );
        },
      },
    ],
    [t, navigate, handleDelete /* isAdmin */]
  );

  return (
    <>
      <PageHeader
        title={t("titlePages.companies")}
        link={{ url: "/companies/add", icon: "plus", label: t("common.add") }}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
          />
        </section>
      </main>
    </>
  );
}

export default Companies;
