import { useState, useCallback, useEffect } from "react";
import CancellablePromise from "common/utils/CancellablePromise";

export type UseAsyncOptions = {
  ready?: boolean;
  manual?: boolean;
  storeData?: boolean;
  cachePrefix?: string;
  postExecute?: (data: any) => any;
};

export type UseAsyncType<T> = {
  execute: (...args: any[]) => CancellablePromise<unknown>;
  isProcessing: boolean;
  data: T | null;
  error: string | null;
  isPreviousData: boolean;
  isDataValid: boolean;
  setData: (value: T | null | ((value: T | null) => T | null)) => void;
};

const useAsync = <T,>(
  asyncFn: (...args: any[]) => CancellablePromise<unknown> | Promise<unknown>,
  options: UseAsyncOptions = {}
): UseAsyncType<T> => {
  const opts = {
    ready: true,
    manual: false,
    storeData: false,
    cachePrefix: "",
    ...options,
  };

  const [isProcessing, setIsProcessing] = useState(0);
  const [isPreviousData, setIsPreviousData] = useState(false);
  const [isDataValid, setDataValid] = useState(false);
  const [data, setData] = useState<T | null>(null as unknown as T);
  const [error, setError] = useState<string | null>(null);
  const { storeData, postExecute } = opts;

  const execute = useCallback(
    (...args: any[]) => {
      setIsProcessing((v) => v + 1);
      setError(null);
      if (storeData) {
        setIsPreviousData(true);
        setDataValid(false);
      }
      const promise = asyncFn(...args);
      const { cancel } = promise as CancellablePromise<unknown>;

      const newPromise = new CancellablePromise((resolve, reject) => {
        promise
          .then((response: unknown) => {
            if (storeData) {
              let result;
              if (postExecute) {
                result = postExecute(response);
              } else {
                result = response;
              }
              setData(result);
              setDataValid(true);
              setIsPreviousData(false);
            }
            resolve(response);
          })
          .catch((e: Error) => {
            setError(e.message);
            reject(e);
          })
          .finally(() => {
            setIsProcessing((v) => v - 1);
          });
      });

      newPromise.cancel = cancel;

      return newPromise;
    },
    [setData, asyncFn, storeData, postExecute]
  );

  useEffect(() => {
    let promise: CancellablePromise<unknown>;

    if (!opts.manual && opts.ready) {
      promise = execute();
    }
    return () => {
      if (promise && promise.cancel) {
        promise.cancel();
      }
    };
  }, [execute, opts.manual, opts.ready]);

  return {
    execute,
    isProcessing: Boolean(isProcessing),
    data,
    error,
    isPreviousData,
    isDataValid,
    setData,
  };
};

export default useAsync;
