// import Card from 'common/components/Card/Card';
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import UserSelect from "common/components/CustomSelect/UserSelect/UserSelect";
import FeedSelect from "common/components/CustomSelect/FeedSelect/FeedSelect";
import CommentSelect from "common/components/CustomSelect/CommentSelect/CommentSelect";
// import useAuth from 'common/contexts/auth';
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormFooter } from "common/components/FormUtilities/FormUtilities";
// import CompanySelect from 'requests/components/CompanySelect';
// import useCompanies from 'requests/contexts/companies';

function CommentForm({ onSubmit, data }: any) {
  const { t } = useTranslation();
  // const { user: currentUser } = useAuth();
  // const { currentCompany } = useCompanies();
  const initialValues = useMemo(
    () =>
      data?.id
        ? {
            ...data,
            user: data.user.id,
            feed: data.feed.id,
          }
        : {
            name: "",
          },
    [data /* currentCompany */]
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section className="p-8">
            <FormScrollError />

            <FormControl
              type="custom"
              name="user"
              title={t("common.user")}
              required
            >
              <UserSelect />
            </FormControl>
            <FormControl
              type="custom"
              name="feed"
              title={t("common.feed")}
              required
            >
              <FeedSelect />
            </FormControl>
            <FormControl name="content" title={t("common.content")} required />
            <FormControl type="custom" name="likes" title={t("common.likes")}>
              <UserSelect multiple />
            </FormControl>
            <FormControl
              type="custom"
              name="responseOf"
              title={t("common.responseOf")}
            >
              <CommentSelect />
            </FormControl>
          </section>

          <FormFooter onClick={handleSubmit} />
        </form>
      )}
    />
  );
}

export default CommentForm;
