import PageHeader from "common/components/PageHeader/PageHeader";
import Select from "common/components/Select/Select";
import StatusBadge from "common/components/StatusBadge/StatusBadge";
import Table from "common/components/Table/Table";
import TableAvatarTitle from "common/components/TableUtilities/TableAvatarTitle/TableAvatarTitle";
import {
  TableDeleteButton,
  TableEditButton,
} from "common/components/TableUtilities/TableButtons/TableButtons";
// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

function Vehicles() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    itemsPerPage,
    itemsCount,
    setPageIndex,
    error,
    pageIndex,
    removeListItem,
  } = useList("vehicles", {
    defaultFilters: {},
    defaultSort: "name:ASC",
    populate: ["user"],
  });
  const { removeItem } = useItem("vehicles", "");

  const { navigate } = useRouter();
  // const { isAdmin } = useAuth();

  const status = useMemo(
    () => [
      {
        value: "refused",
        label: t("common.refused"),
      },
      {
        value: "in_waiting",
        label: t("common.in_waiting"),
      },
      {
        value: "accepted",
        label: t("common.accepted"),
      },
    ],
    [t]
  );

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );

  // const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      // setAlert(error, 'danger');
    }
  }, [error /* setAlert */]);

  const columns = useMemo(
    () => [
      {
        Header: t("common.name"),
        filterName: "name$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { id, name },
          },
        }: any) => (
          <TableAvatarTitle
            title={name || undefined}
            link={`/vehicles/edit/${id}`}
          />
        ),
      },
      {
        Header: t("common.username"),
        filterName: "user][username$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { user },
          },
        }: any) => (
          <TableAvatarTitle
            title={user?.username}
            link="#"
            secondTitle={`${user?.username}`}
          />
        ),
      },
      {
        Header: t("common.status"),
        filterName: "status$contains",
        // eslint-disable-next-line react/no-unstable-nested-components
        filterComponent: (props: any) => (
          <Select
            options={status}
            placeholder={t("common.status")}
            {...props}
          />
        ),
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { status: s },
          },
        }: any) => <StatusBadge status={s} />,
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        // eslint-disable-next-line
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => {
          // if (!isAdmin) return null;

          return (
            <div className="flex justify-end">
              <TableEditButton
                onClick={() => navigate(`/vehicles/edit/${id}`)}
              />
              <TableDeleteButton onClick={() => handleDelete(id)} />
            </div>
          );
        },
      },
    ],
    [t, navigate, handleDelete /* isAdmin */, status]
  );

  return (
    <>
      <PageHeader
        title={t("common.vehicles")}
        link={{ url: "/vehicles/add", icon: "plus", label: t("common.add") }}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            itemsPerPage={itemsPerPage}
            itemsCount={itemsCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
          />
        </section>
      </main>
    </>
  );
}

export default Vehicles;
