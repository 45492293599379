import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import Button from "common/components/Button/Button";

type EditProps = {
  onClick: () => void;
};

type DeleteProps = {
  onClick: () => void;
  confirmMessage?: string;
};

type ValidationProps = {
  onClick: () => void;
};

type RefusalProps = {
  onClick: () => void;
};

function TableEditButton({ onClick }: EditProps) {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      icon={<PencilIcon className="h-5 w-5" aria-hidden="true" />}
      color="primary"
      // transparent
      className="mr-2 bg-cyan-500 hover:bg-cyan-600 px-1 py-1"
      title={t("actions.edit")}
    />
  );
}

function TableDeleteButton({ onClick, confirmMessage }: DeleteProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      icon={<TrashIcon className="h-5 w-5" aria-hidden="true" />}
      color="danger"
      confirm
      // transparent
      confirmMessage={confirmMessage || t("common.delete")}
      className="bg-neutral-400 hover:bg-neutral-500 px-1 py-1"
      title={t("actions.delete")}
    />
  );
}

function TableValidationButton({ onClick }: ValidationProps) {
  return (
    <Button
      onClick={onClick}
      icon={<CheckIcon className="h-5 w-5" aria-hidden="true" />}
      // transparent
      className="mr-2 bg-green-500 hover:bg-green-600 px-1 py-1"
    />
  );
}

function TableRefusalButton({ onClick }: RefusalProps) {
  return (
    <Button
      onClick={onClick}
      icon={<XIcon className="h-5 w-5" aria-hidden="true" />}
      color="primary"
      // transparent
      className="mr-2 bg-rose-500 hover:bg-rose-600 px-1 py-1"
    />
  );
}

export {
  TableEditButton,
  TableDeleteButton,
  TableValidationButton,
  TableRefusalButton,
};
