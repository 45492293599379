import React from "react";
import PageHeader from "common/components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import WaitingVehicleDocuments from "./WaitingVehicleDocuments";
import WaitingUserDocuments from "./WaitingUserDocuments";

function WaitingDocuments() {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t("common.waitingDocuments")}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      <main className="flex-1 px-8 py-8">
        <ul className="divide-y divide-gray-200">
          <li className="py-4">
            <h2 className="text-xl font-bold text-gray-900 sm:text-xl">
              {t("common.vehicleDocuments")}
            </h2>
            <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <WaitingVehicleDocuments />
            </section>
          </li>
          <li className="py-4">
            <h2 className="text-xl font-bold text-gray-900 sm:text-xl">
              {t("common.userDocuments")}
            </h2>
            <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <WaitingUserDocuments />
            </section>
          </li>
        </ul>
      </main>
    </>
  );
}

export default WaitingDocuments;
