/* eslint-disable no-console */
import React, { createContext, useCallback, useMemo, useState } from "react";
import { Constants } from "constants/Constants";
import {
  removeStoredData,
  storeData,
  getStoredData,
} from "common/utils/fnAsyncStorage";
import fetchJSON from "common/utils/fetchJSON";
import { useTranslation } from "react-i18next";
import { User } from "../../types/User";
// import useNotification from './NotificationProvider';

type Auth = {
  user?: User;
  token?: string;
  signin: (identifier: string, password: string) => Promise<boolean | User>;
  refreshUser: () => Promise<User>;
  register: (values: any) => Promise<boolean>;
  updateUser: (payload: any) => Promise<void>;
  isFetching: boolean;
  isSignInError: boolean;
  errorMessage: string | undefined;
  cleanError: () => void;
  signout: (userId?: string | number) => Promise<void>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (password: string, code: string) => Promise<boolean>;
};

export const AuthContext = createContext<Auth>({} as Auth);

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { t } = useTranslation();
  const [user, setUser] = useState<User>();
  const [currentJwt, setCurrentJwt] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [isSignInError, setIsSignInError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  // const { pushToken } = useNotification();

  const signout = useCallback(
    async (/* userId?: string | number */) => {
      /* if (pushToken && userId) {
        fetchJSON({
          url: `users/${userId}`,
          method: 'PUT',
          payload: { pushToken: '' },
        });
      } */
      setUser(undefined);
      setCurrentJwt(undefined);
      await removeStoredData("token");
      await removeStoredData("user");
    },
    [] /* pushToken */
  );

  const refreshUser = useCallback(async () => {
    try {
      setIsFetching(true);
      const token = await getStoredData("token");

      if (token) {
        const res = await fetchJSON({
          url: "users/me",
          method: "GET",
        });

        if (res) {
          if (["Admin", "Company"].includes(res.role.name)) {
            /* if (pushToken) {
              if (pushToken !== res.pushToken) {
                fetchJSON({
                  url: `users/${res.id}`,
                  method: 'PUT',
                  payload: { pushToken },
                });
              }
            } */
            setUser(res);
            return res;
          }
        }
      }

      signout();
      return false;
    } catch {
      // if users/me is not accessible then the token expired
      signout();
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [signout]); // pushToken

  const signin = useCallback(
    async (email: string, password: string): Promise<boolean | User> => {
      const payload = {
        identifier: email,
        password,
      };

      setIsSignInError(false);
      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/local`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();

        if (res.status === 200) {
          try {
            await storeData("token", resJson.jwt);
            setCurrentJwt(resJson.jwt);
            const u = await refreshUser();

            return u;
          } catch {
            setIsSignInError(true);
            setErrorMessage(resJson.msg_err);
            return true;
          }
        }
        setIsSignInError(true);
        setErrorMessage(resJson.msg_err);
        return true;
      } catch {
        setIsSignInError(true);
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    [refreshUser]
  );

  const register = useCallback(
    async (values: User): Promise<boolean> => {
      const payload = {
        ...values,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/local/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();
        if (res.status === 200) {
          return resJson;
        }
        let errorMsg: any = "Erreur serveur";
        if (resJson?.error?.message === "Email is already taken") {
          errorMsg = t("auth.emailAlreadyTaken");
        } else if (
          resJson?.error?.message ===
          "An error occurred during account creation"
        ) {
          errorMsg = t("auth.pseudoAlreadyTaken");
        }
        throw new Error(errorMsg);
      } finally {
        setIsFetching(false);
      }
    },
    [t]
  );

  const cleanError = useCallback(() => {
    setIsSignInError(false);
    setErrorMessage(undefined);
  }, []);

  const updateUser = useCallback(
    async (payload: any) => {
      setIsFetching(true);
      try {
        await fetchJSON({
          url: `users/${user?.id}`,
          method: "PUT",
          payload,
        });

        refreshUser();
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    [user, refreshUser]
  );

  const forgotPassword = useCallback(
    async (email: string): Promise<boolean> => {
      const payload = {
        email,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/forgot-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();
        if (res.status === 200) {
          console.log("Un mail a été envoyé");
          return false;
        }
        console.log("Erreur lors de l'envoi");
        setErrorMessage(resJson.msg_err);
        return true;
      } catch {
        console.log("Erreur lors de l'envoi");
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const resetPassword = useCallback(
    async (password: string, code: string): Promise<boolean> => {
      const payload = {
        code,
        password,
        passwordConfirmation: password,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/reset-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        await res.json();
        if (res.status === 200) {
          return false;
        }
        throw new Error("Erreur lors du changement de mot de passe");
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const value: Auth = useMemo(
    () => ({
      user,
      token: currentJwt,
      signin,
      refreshUser,
      register,
      updateUser,
      isFetching,
      isSignInError,
      errorMessage,
      cleanError,
      signout,
      forgotPassword,
      resetPassword,
    }),
    [
      user,
      currentJwt,
      signin,
      refreshUser,
      register,
      updateUser,
      isFetching,
      isSignInError,
      errorMessage,
      cleanError,
      signout,
      forgotPassword,
      resetPassword,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
