import FormControl from "common/components/FormControl/FormControl";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo.svg";

function Login() {
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const { user, signin, isSignInError: isFetchingError } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  /* const handleForgotPasswordPressed = () => {
    navigate("/ForgotPassword");
  }; */

  const handleLogin = async ({ email, password }: any) => {
    const isUserLogged = await signin(email, password);
    if (typeof isUserLogged !== "boolean" && "id" in isUserLogged) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isFetchingError) {
      /* setAlert({
        color: theme.colors.statusRed,
        message: t('alert.invalidCredentials'),
        title: t('alertTitle.invalidCredentials'),
      }); */
    }
  }, [isFetchingError /* setAlert */]);

  if (user) {
    return null;
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="Ciligo Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Ciligo Administration
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            onSubmit={handleLogin}
            render={({ handleSubmit }) => (
              <form className="space-y-6" onSubmit={handleSubmit} noValidate>
                <FormControl name="email" title={t("common.email")} />
                <FormControl
                  type="password"
                  name="password"
                  title={t("common.password")}
                />
                <div className="flex items-center justify-between" />

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("common.login")}
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
