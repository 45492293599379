import PageHeader from "common/components/PageHeader/PageHeader";
import Table from "common/components/Table/Table";
import TableAvatarTitle from "common/components/TableUtilities/TableAvatarTitle/TableAvatarTitle";
import {
  TableDeleteButton,
  TableEditButton,
  TableRefusalButton,
  TableValidationButton,
} from "common/components/TableUtilities/TableButtons/TableButtons";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

function Events() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    itemsPerPage,
    itemsCount,
    setPageIndex,
    pageIndex,
    removeListItem,
  } = useList("events", {
    defaultFilters: {},
    defaultSort: "id:DESC",
    populate: ["company", "city"],
  });
  const { removeItem, updateItem } = useItem("events", "");

  const { navigate } = useRouter();

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );
  console.log({ items });
  const handleBlock = useCallback(
    async (item: any) => {
      const updatedItem = { ...item, blocked: true };
      await updateItem(updatedItem.id, updatedItem, false);
      fetchItems();
    },
    [updateItem, fetchItems]
  );

  const handleUnblock = useCallback(
    async (item: any) => {
      const updatedItem = { ...item, blocked: false };
      await updateItem(updatedItem.id, updatedItem, false);
      fetchItems();
    },
    [updateItem, fetchItems]
  );
  const columns = useMemo(
    () => [
      {
        Header: t("common.eventName"),
        filterName: "",
        className: "table-start",
        Cell: ({
          row: {
            original: { id, name },
          },
        }: any) => (
          <TableAvatarTitle title={name} link={`/events/edit/${id}`} />
        ),
      },
      {
        Header: t("forms.city"),
        filterName: "city][name$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { city },
          },
        }: any) => <span>{city?.name}</span>,
      },
      {
        Header: t("forms.company"),
        filterName: "company][name$contains",
        className: "table-start",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: { company },
          },
        }: any) => <span>{company?.name}</span>,
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        Cell: ({ row: { original } }: any) => {
          return (
            <div className="flex justify-end">
              <TableEditButton
                onClick={() => navigate(`/events/edit/${original.id}`)}
              />
              <TableDeleteButton onClick={() => handleDelete(original.id)} />
            </div>
          );
        },
      },
    ],
    [t, navigate, handleDelete]
  );
  return (
    <>
      <PageHeader
        title={t("common.event")}
        link={{ url: "/events/add", icon: "plus", label: t("common.add") }}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            itemsPerPage={itemsPerPage}
            itemsCount={itemsCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
          />
        </section>
      </main>
    </>
  );
}

export default Events;
