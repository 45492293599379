import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";

const processOptions = (options) =>
  options.map(({ id, attributes }) => ({
    value: id,
    label: attributes.code,
  }));

function RideSelect(props) {
  return (
    <RemoteSelect url="rides" processOptions={processOptions} {...props} />
  );
}

export default RideSelect;
