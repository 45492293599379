import { Suspense } from "react";
import { AuthProvider } from "common/providers/AuthProvider";
import { LoadingProvider } from "common/providers/LoadingProvider";
// import { AlertProvider } from 'common/providers/AlertProvider';
// import { NotificationProvider } from 'common/providers/NotificationProvider';

type Props = {
  children: JSX.Element;
};

function AppProvider({ children }: Props) {
  return (
    <Suspense>
      <LoadingProvider>
        <AuthProvider>{children}</AuthProvider>
      </LoadingProvider>
    </Suspense>
  );
}

export default AppProvider;
