import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "common/components/Routes/Routes";
import Layout from "common/components/Layout/Layout";

import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import routes from "./routes";
import AppProvider from "./AppProvider";
import "./i18n";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(duration);

function App() {
  return (
    <Router>
      <AppProvider>
        <Layout>
          <Routes routes={routes} />
        </Layout>
      </AppProvider>
    </Router>
  );
}

export default App;
