import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";

const processOptions = (options) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function CitySelect(props) {
  return (
    <RemoteSelect url="cities" processOptions={processOptions} {...props} />
  );
}

export default CitySelect;
