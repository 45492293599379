// import { rootloader } from 'common/components/RootLoader/RootLoader';
import { useAuth } from "common/hooks/useAuth";
import useRouter from "common/hooks/use-router";
import Page404 from "common/pages/Page404";
import { useEffect, useMemo } from "react";
import { Route, Routes as RRoutes } from "react-router-dom";
import Login from "common/pages/Login";

const getUserRoutes = (routes, user) =>
  routes.map((route) => {
    let { Component } = route;
    const { path, roles = null, exact = true } = route;

    if (roles) {
      if (user && user.role) {
        if (!roles.includes(user.role.name)) {
          Component = Page404;
        }
      } else {
        return null;
      }
    }

    return (
      <Route key={path} path={path} exact={exact} element={<Component />} />
    );
  });

// eslint-disable-next-line react/prop-types
function Routes({ routes }) {
  const { user, refreshUser, isUserRefreshing } = useAuth();
  const { navigate } = useRouter();

  useEffect(() => {
    (async () => {
      const res = await refreshUser();
      if (!res) {
        navigate("/login");
      }
    })();
  }, [navigate, refreshUser]);

  const userRoutes = useMemo(() => {
    if (user) {
      return getUserRoutes(routes, user);
    }

    return <Route path="/login" element={<Login />} />;
  }, [routes, user]);

  return isUserRefreshing ? null : <RRoutes>{userRoutes}</RRoutes>;
}

/*
Routes.propTypes = {
  routes: PropTypes.array.isRequired,
}; */

export default Routes;
