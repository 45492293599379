import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/solid";
import Button from "common/components/Button/Button";

type FooterProps = {
  onClick: () => void;
  label?: string;
};

function FormFooter({ onClick, label }: FooterProps) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center mt-5 sticky bottom-0 bg-white p-2">
      <Button
        onClick={onClick}
        icon={<CheckIcon className="h-5 w-5" aria-hidden="true" />}
        transparent
        className="bg-green-500 hover:bg-green-600 px-3 py-3 text-base"
        label={label || t("common.send")}
        // disabled={submitting || pristine}
        // isSubmitting={submitting}
      />
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { FormFooter };
