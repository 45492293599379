import { Types } from "types/Types";
// import useTheme from 'hooks/useTheme';
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { I18nKey } from "i18n";
import { JText } from "common/components/Text/Text";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import Input, { InputProps } from "../FormInput/FormInput";

type Props = InputProps & {
  title?: string;
  titleKey?: I18nKey;
  subtitle?: string;
  subtitleKey?: I18nKey;
  leftIconType?: Types["iconTypes"];
  leftIconName?: string;
  leftIconSizeName?: string; // FontSizeType;
  leftIconColorName?: string; // ColorType;
  inline?: boolean;
  style?: object; // StyleProp<ViewStyle>;
  editable?: boolean;
  rightIconName?: string;
  name: string;
  required?: boolean;
  validate?: (value: any) => void | undefined | string;
  type?: string;
  onChange?: (value: any) => void;
  autoCompleteUrl?: string;
};

const keepEmptyValue = (value: any) => (value === "" ? null : value);

function FormControl({
  title,
  titleKey,
  subtitle,
  subtitleKey,
  leftIconType,
  leftIconName,
  leftIconSizeName,
  // leftIconColorName = "dark",
  // inline = false,
  style,
  editable = true,
  name,
  validate,
  required = false,
  type = "text",
  onChange = () => {},
  ...rest
}: Props) {
  const { t } = useTranslation();

  const reqRule = (value: any) =>
    value !== "" && value != null ? undefined : t("common.fieldRequired");
  const checkboxReq = (value: any) =>
    value === true ? undefined : t("common.fieldRequired");

  let validator = !validate && required ? reqRule : validate;

  validator = type === "checkbox" && required ? checkboxReq : validator;

  return (
    <Field
      validate={validator}
      required={required}
      parse={keepEmptyValue}
      name={name}
    >
      {({ input, meta }) => {
        const inputClassName =
          meta &&
          (meta.touched || meta.submitFailed) &&
          (meta.error || meta.submitError)
            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            : "block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md";

        return (
          <div className="mb-3">
            {(title || titleKey) && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label className="block text-sm font-medium text-gray-700">
                <JText isBold label={title} labelKey={titleKey} />
                {required && <JText label="*" />}
              </label>
            )}
            {(subtitle || subtitleKey) && (
              <p className="mt-2 text-sm text-gray-500">
                <JText isItalic label={subtitle} labelKey={subtitleKey} />
              </p>
            )}
            <div className="mt-1 relative rounded-md shadow-sm">
              <Input
                className={inputClassName}
                {...input}
                {...rest}
                onChange={(val: any) => {
                  onChange(val);
                  input.onChange(val);
                }}
                type={type}
                disabled={!editable}
              />
              {meta &&
                (meta.touched || meta.submitFailed) &&
                (meta.error || meta.submitError) && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
            </div>
            {meta &&
              (meta.touched || meta.submitFailed) &&
              (meta.error || meta.submitError) && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {meta.error || meta.submitError}
                </p>
              )}
          </div>
        );
      }}
    </Field>
  );
}

export default FormControl;
