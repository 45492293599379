import useRouter from "common/hooks/use-router";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  link?: string;
  secondTitle?: string;
  isBlocked?: boolean;
  isConfirmed?: boolean;
};

function TableAvatarTitle({
  title = "",
  link = "",
  secondTitle,
  isBlocked = false,
  isConfirmed = true,
}: Props) {
  const { navigate } = useRouter();
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      <div
        onClick={() => navigate(link)}
        className={`min-w-[2rem] h-8 w-8 flex items-center justify-center ${
          isConfirmed
            ? isBlocked
              ? "bg-red-500"
              : "bg-green-500"
            : "bg-yellow-400"
        } rounded-full text-lg text-white cursor-pointer`}
        title={
          isConfirmed
            ? isBlocked
              ? t("status.banned")
              : t("status.valid")
            : t("status.notConfirmed")
        }
      >
        <span>{title[0]}</span>
      </div>
      <span
        className="ml-2 whitespace-normal cursor-pointer"
        onClick={() => navigate(link)}
      >
        {secondTitle || title}
      </span>
    </div>
  );
}

export default TableAvatarTitle;
