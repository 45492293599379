import useFetch from "common/hooks/useFetch";
import React, { useEffect, useState } from "react";
import Select from "../Select/Select";

// filters: ['paramKey']

export type RemoteSelectProps = {
  onDataLoaded?: (value: any) => void;
  url?: string;
  filters?: any;
  processOptions?: (value: any) => void;
  additionnalOptions?: any;
};

export function RemoteSelect({
  onDataLoaded = () => {},
  url,
  filters = [],
  processOptions = () => {},
  additionnalOptions = () => {},
  ...rest
}: RemoteSelectProps) {
  const [options, setOptions] = useState<any>([]);
  const [params, setParams] = useState<any>({});

  let paramsUpdated = false;
  const newParams: any = {};

  filters.forEach((f: string) => {
    newParams[f] = (rest as any)[f];
    if (newParams[f] !== params[f]) {
      paramsUpdated = true;
    }
  });

  if (paramsUpdated) {
    setParams(newParams);
  }

  const getUrl = () => {
    const esc = encodeURIComponent;
    const queryParams = Object.keys(params)
      .filter((key) => params[key] != null)
      .map((key) => `${esc(key)}=${esc(params[key])}`)
      .join("&");

    return `${url}${queryParams ? "?" : ""}${queryParams}`;
  };

  const { data }: any = useFetch(getUrl());

  useEffect(() => {
    let newData = [];

    if (data && data.data) {
      newData = Array.isArray(data.data) ? [...data.data] : [];
    } else {
      newData = data && Array.isArray(data) ? [...data] : [];
    }

    if (data && !newData.length) newData.push(data);
    if (onDataLoaded) {
      onDataLoaded(newData);
    }

    if (additionnalOptions.length && Array.isArray(newData)) {
      newData = [...additionnalOptions, ...newData];
    }

    const opts = processOptions ? processOptions(newData) : newData;

    if (JSON.stringify(options) !== JSON.stringify(opts)) {
      setOptions(opts);
    }
  }, [
    additionnalOptions,
    data,
    onDataLoaded,
    options,
    processOptions,
    setOptions,
  ]);

  return (
    <Select
      options={options}
      // isLoading={isFetching}
      {...rest}
    />
  );
}

export default RemoteSelect;
