import PageHeader from "common/components/PageHeader/PageHeader";
import Table from "common/components/Table/Table";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TableAvatarTitle from "common/components/TableUtilities/TableAvatarTitle/TableAvatarTitle";
import { TableDeleteButton } from "common/components/TableUtilities/TableButtons/TableButtons";
import DatePicker from "common/components/DatePicker/DatePicker";
import { DocumentIcon, CollectionIcon } from "@heroicons/react/outline";
import Select from "common/components/Select/Select";

const urlLabel = "rides";

function Rides() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    itemsCount,
    itemsPerPage,
    setPageIndex,
    error,
    pageIndex,
    removeListItem,
  } = useList(urlLabel, {
    defaultFilters: {},
    defaultSort: "id:DESC",
    populate: [
      "driver",
      "driver.profilePicture",
      "departureLocation",
      "arrivalLocation",
    ],
  });
  const { removeItem } = useItem(urlLabel, "");

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );

  useEffect(() => {
    if (error) {
      // setAlert(error, 'danger');
    }
  }, [error /* setAlert */]);

  const recurrentTypes = useMemo(
    () => [
      {
        value: "1",
        label: t("common.recurrentMain"),
      },
    ],
    [t]
  );

  const status = useMemo(
    () => [
      {
        value: "incoming",
        label: t("status.incoming"),
      },
      {
        value: "in_progress",
        label: t("status.in_progress"),
      },
      {
        value: "finished",
        label: t("status.finished"),
      },
    ],
    [t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("common.driver"),
        filterName: "driver][username$contains",
        className: "table-start",
        Cell: ({
          row: {
            original: { driver },
          },
        }: any) => <TableAvatarTitle title={driver?.username} link="#" />,
      },
      {
        Header: t("common.departureLocation"),
        filterName: "departureLocation][name$contains",
        className: "table-start",
        Cell: ({
          row: {
            original: { departureLocation },
          },
        }: any) => (
          <span className="clickable">{departureLocation?.name} </span>
        ),
      },
      {
        Header: t("common.departureTime"),
        filterName: "departureTime$date",
        filterComponent: (props: any) => (
          <DatePicker placeholder={t("common.departureTime")} {...props} />
        ),
        className: "table-start",
        Cell: ({
          row: {
            original: { departureTime },
          },
        }: any) => (
          <span className="clickable">
            {dayjs(departureTime).format("DD/MM/YYYY hh:mm")}
          </span>
        ),
      },

      {
        Header: t("common.arrivalLocation"),
        filterName: "arrivalLocation][name$contains",
        className: "table-start",
        Cell: ({
          row: {
            original: { arrivalLocation },
          },
        }: any) => <span className="clickable">{arrivalLocation?.name}</span>,
      },

      {
        Header: t("common.arrivalTime"),
        filterName: "arrivalTime$eq",
        filterComponent: (props: any) => (
          <DatePicker placeholder={t("common.arrivalTime")} {...props} />
        ),
        className: "table-start",
        Cell: ({
          row: {
            original: { arrivalTime },
          },
        }: any) => (
          <span className="clickable">
            {dayjs(arrivalTime).format("DD/MM/YYYY hh:mm")}
          </span>
        ),
      },
      {
        Header: t("common.status"),
        filterName: "status$contains",
        filterComponent: (props: any) => (
          <Select
            clearable
            options={status}
            placeholder={t("common.status")}
            {...props}
          />
        ),
        className: "table-start",
        Cell: ({
          row: {
            original: { status: s },
          },
        }: any) => <span className="clickable">{t(`status.${s}`)}</span>,
      },
      {
        Header: t("common.recurrent"),
        filterName: "isRecurrent$eq",
        filterComponent: (props: any) => (
          <Select
            clearable
            options={recurrentTypes}
            placeholder={t("common.recurrent")}
            {...props}
          />
        ),
        className: "table-start",
        Cell: ({
          row: {
            original: { isRecurrent, recurrentCreationDate },
          },
        }: any) => (
          <span className="flex flex-row items-center">
            {isRecurrent ? (
              <>
                <CollectionIcon className="h-6 w-6 mr-1" />
                {t("common.recurrentMain")}
              </>
            ) : recurrentCreationDate ? (
              <>
                <DocumentIcon className="h-6 w-6 mr-1" />
                {t("common.recurrentInstance")}
              </>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => {
          return (
            <div className="flex justify-end">
              <TableDeleteButton onClick={() => handleDelete(id)} />
            </div>
          );
        },
      },
    ],
    [t, handleDelete, recurrentTypes, status]
  );

  return (
    <>
      <PageHeader
        title={t("common.rides")}
        /* link={{
          url: `/${urlLabel}/add`,
          icon: "plus",
          label: t("common.add"),
        }}
        subLink={{
          url: "/configure",
          icon: "arrow-left",
          label: t("common.back"),
        }} */
      />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
          />
        </section>
      </main>
    </>
  );
}

export default Rides;
