import Button from "common/components/Button/Button";
import PageHeader from "common/components/PageHeader/PageHeader";
import Table from "common/components/Table/Table";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/solid";

function Reports() {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
  } = useList("reports", {
    defaultFilters: {},
    defaultSort: "id:DESC",
    populate: [
      "userReported",
      "userReporting",
      "message",
      "comment",
      "notation",
    ],
  });
  const { removeItem } = useItem("reports", "");

  const { navigate } = useRouter();

  const handleDelete = useCallback(
    async (id: any) => {
      removeListItem(id);
      await removeItem(id);
      fetchItems();
    },
    [removeListItem, fetchItems, removeItem]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("reports.reported"),
        filterName: "userReported][username$contains",
        className: "table-start",
        Cell: ({
          row: {
            original: { id, userReported },
          },
        }: any) => (
          <div
            onClick={() => navigate(`/reports/${id}`)}
            className="crud-item-avatar"
          >
            <span>{userReported && userReported.username}</span>
          </div>
        ),
      },
      {
        Header: t("reports.reporting"),
        filterName: "userReporting][username$contains",
        className: "table-start",
        Cell: ({
          row: {
            original: { id, userReporting },
          },
        }: any) => (
          <div
            onClick={() => navigate(`/reports/${id}`)}
            className="crud-item-avatar"
          >
            <span>{userReporting && userReporting.username}</span>
          </div>
        ),
      },

      {
        Header: "Type",
        filterName: "",
        className: "table-start",
        Cell: ({
          row: {
            original: { id, message, comment, notation },
          },
        }: any) => (
          <span
            className="clickable"
            onClick={() => navigate(`/reports/${id}`)}
          >
            {message
              ? "message"
              : notation
              ? "notation"
              : comment
              ? "comment"
              : "no-type"}
          </span>
        ),
      },
      {
        Header: t("common.actions"),
        className: "table-actions",
        // eslint-disable-next-line
      Cell: ({ row: { original: { id } } }: any) => {
          // if (!isAdmin) return null;

          return (
            <Button
              onClick={() => handleDelete(id)}
              icon={<TrashIcon className="h-4 w-4" aria-hidden="true" />}
              color="danger"
              confirm
              transparent
              confirmMessage={t("common.delete")}
            />
          );
        },
      },
    ],
    [t, navigate, handleDelete]
  );

  return (
    <>
      <PageHeader title={t("menu.reports")} />
      <main className="flex-1">
        <section className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <Table
            data={items ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
          />
        </section>
      </main>
    </>
  );
}

export default Reports;
