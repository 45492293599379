import DP from "react-datepicker";
import fr from "date-fns/locale/fr";
import "./DatePicker.css";
import { format } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

type Props = {
  onStartChange?: (value: any) => void;
  startDate?: any;
  onEndChange?: (value: any) => void;
  endDate?: any;
  placeholder?: string;
};

function DateRangePicker({
  placeholder = "",
  endDate = null,
  startDate = null,
  onEndChange = () => {},
  onStartChange = () => {},
}: Props) {
  const onChange = (dates: any) => {
    const [start, end] = dates;
    onStartChange(start);
    onEndChange(end);
  };

  return (
    <DP
      dateFormat="dd/MM/yyyy"
      locale={fr}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      placeholderText={placeholder}
      selectsRange
      monthsShown={2}
      portalId="date-portal"
      renderCustomHeader={({
        monthDate,
        // customHeaderCount,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex items-center justify-between px-2 py-2">
          <span className="text-lg text-gray-700">
            {format(monthDate, "MMMM yyyy", { locale: fr })}
          </span>

          <div className="space-x-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`
                        ${
                          prevMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                        inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                    `}
            >
              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
            </button>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`
                        ${
                          nextMonthButtonDisabled &&
                          "cursor-not-allowed opacity-50"
                        }
                        inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                    `}
            >
              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>
      )}
    />
  );
}

export default DateRangePicker;
