// import Loader from 'common/components/Loader/Loader';
import PageHeader from "common/components/PageHeader/PageHeader";
// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CityForm from "./CityForm";

function City() {
  const { t } = useTranslation();
  const router = useRouter();
  // const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "cities",
    id
  );

  const back = useCallback(() => {
    if (!error) {
      // setAlert(t('common.saved'), 'success');
      router.navigate("/cities");
    } else {
      // setAlert(error, 'danger');
    }
  }, [/* setAlert */ router, error]);

  const onSubmit = useCallback(
    async (dataCity: any) => {
      const city = { ...dataCity };

      try {
        if (city.id) {
          await updateItem(city.id, city);
        } else {
          await saveItem(city);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? "edit" : "add"}`)}
        subLink={{
          url: "/cities",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      {isFetching && id && !item ? null : (
        <CityForm onSubmit={onSubmit} city={item} />
      )}
    </>
  );
}

export default City;
