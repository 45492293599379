/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { useCallback } from "react";

function ButtonGroup({
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  // required,
  options,
  // className,
}) {
  const handleChange = useCallback(
    (val) => {
      if (multiple) {
        if (!Array.isArray(value)) {
          onChange([val]);
        } else if (value.includes(val)) {
          const newValue = value.filter((v) => v !== val);

          if (clearable || newValue.length > 0) {
            onChange(newValue);
          }
        } else {
          onChange([...value, val]);
        }
      } else {
        onChange(val);
      }
    },
    [onChange, multiple, clearable, value]
  );

  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      {options.map(({ value: val, label }, index) => {
        let selected = false;

        if (multiple) {
          selected = Array.isArray(value) && value.includes(val);
        } else {
          selected = val === value;
        }

        let className =
          "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500";
        if (index !== 0) {
          className = `-ml-px ${className}`;
        }
        if (index === 0) {
          className = `rounded-l-md ${className}`;
        }
        if (index === options.length - 1) {
          className = `rounded-r-md ${className}`;
        }

        if (selected) {
          className +=
            " border-[#0000ff] text-[#0000ff] z-10 outline-none ring-1 ring-indigo-500 border-indigo-500";
        }

        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="button"
            onClick={() => {
              handleChange(val);
            }}
            className={className}
            disabled={disabled}
          >
            {label}
          </button>
        );
      })}
    </span>
  );
}

ButtonGroup.propTypes = {
  value: PropTypes.any,
  children: PropTypes.element,
  className: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  "data-cy": PropTypes.string,
};

ButtonGroup.defaultProps = {
  value: null,
  onChange() {},
  className: null,
  children: null,
  clearable: false,
  multiple: false,
  disabled: false,
  required: false,
  "data-cy": "",
  options: [],
};

export default ButtonGroup;
