import React, { useState, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";

export type Props = {
  value?: string;
  onChange: (val: any) => void;
  className?: string;
  debounceTimeout?: number;
  placeholder?: string;
  type?: string;
};

function DebounceInput({
  value,
  onChange,
  debounceTimeout = 300,
  type = "text",
  ...rest
}: Props) {
  const [localValue, setValue] = useState(value);

  const setDebouncedChange = useMemo(
    () => debounce(onChange, debounceTimeout),
    [onChange, debounceTimeout]
  );

  const handleInputChange = useCallback(
    (e: any) => {
      setValue(e.target.value);
      setDebouncedChange(e.target.value);
    },
    [setDebouncedChange]
  );

  return (
    <input
      className="block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
      type={type}
      value={localValue || ""}
      onChange={handleInputChange}
      {...rest}
    />
  );
}

export default DebounceInput;
