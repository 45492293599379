import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import useItem from "../../common/hooks/useItem";
import useRouter from "../../common/hooks/use-router";
import PageHeader from "../../common/components/PageHeader/PageHeader";
import EventForm from "./EventForm";

function EventPage() {
  const { t } = useTranslation();
  const router = useRouter();
  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "events",
    id,
    {
      populate: ["mainPicture", "backgroundPicture", "otherMedia", "company"],
    }
  );
  const back = useCallback(() => {
    if (!error) {
      router.navigate("/events");
    }
  }, [router, error]);

  const onSubmit = useCallback(
    async (dataEvent: any) => {
      const event = { ...dataEvent };

      try {
        if (event.id) {
          await updateItem(event.id, event);
        } else {
          await saveItem(event);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? "edit" : "add"}`)}
        subLink={{
          url: "/events",
          icon: "arrow-left",
          label: t("common.back"),
        }}
      />
      {isFetching && id && !item ? null : (
        <EventForm onSubmit={onSubmit} event={item} />
      )}
    </>
  );
}

export default EventPage;
